export interface IWebPage {
    suffix: string,
    title: string
}
export interface IEntityBrowsingPage extends IWebPage {
    suffix: string,
    title: string
    newItemLabel: string,
}
export interface IEntityCardPage extends IWebPage {
    suffix: string,
    title: string,
    titleFromData?: (v:any)=>(string|JSX.Element),
}
export enum E_PAGE_TYPE {
    BROWSER, CARD, UPLOAD,
}