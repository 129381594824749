import {IEntity} from "../entities";
import {Tables} from "../tables";
import {fieldType, IField} from "../fields";
import {IEntityTableCol} from "../tableColumns";
import {ICard} from "../cardControls";
import {E_ENTITIES} from "../entityDefinitions";

export enum ECityFields {
    id, create_date,update_date, name, aliases, coordinates
}

export const cityFields:{[key in ECityFields]: IField} = {
    [ECityFields.id]: {
        field: "id",
        defaultVal: null,
        type: fieldType.textType,
        title: "id"
    },
    [ECityFields.create_date]: {
        field: "create_date",
        defaultVal: () => { return (new Date()).toISOString() },
        type: fieldType.dateType,
        title: "תאריך יצירה"
    },
    [ECityFields.update_date]: {
        field: "update_date",
        defaultVal: () => { return (new Date()).toISOString() },
        type: fieldType.dateType,
        title: "תאריך עדכון"
    },
    [ECityFields.name]: {
        field: "name",
        validate: [validateCityNameFormat],
        defaultVal: "",
        type: fieldType.textType,
        title: "שם יישוב"
    },
    [ECityFields.aliases]: {
        field: "aliases",
        defaultVal: "",
        type: fieldType.stringArray,
        title: "שמות נוספים"
    },
    [ECityFields.coordinates]: {
        field: "coordinates",
        defaultVal: null,
        validate: [validateCityCoordinates],
        type: fieldType.coordinatesType,
        title: "קואורדינטות"
    },
};

function validateCityNameFormat(cityName:string) {
    const valid = cityName && cityName.length;
    return {valid: !!valid, err: valid ? undefined : "שם שדה לא תקין"}
}

function validateCityCoordinates(coordinates:{x:number, y:number} | null) {
    const valid = !!coordinates;
    return {valid, err: valid ? undefined : "קואורדינטות חסרות"}
}

export const CityBrowserCols:IEntityTableCol[] = [
    {
        field: cityFields[ECityFields.name],
        readonly: true,
        defaultWidth: 10,
    },
    {
        field: cityFields[ECityFields.aliases],
        readonly: true,
        defaultWidth: 30,
    },
    {
        field: cityFields[ECityFields.coordinates],
        readonly: true,
        defaultWidth: 20,
    },
]

export const CityCard:ICard = {
    sections: [
        {
            compact: true,
            hideOnNew: true,
            fields: [
                {
                    field: cityFields[ECityFields.id],
                    readonly: true
                },
                {
                    field: cityFields[ECityFields.create_date],
                    readonly: true
                },
                {
                    field: cityFields[ECityFields.update_date],
                    readonly: true
                }
            ]
        },
        {
            fields: [
                {field: cityFields[ECityFields.name]},
                {field: cityFields[ECityFields.aliases]},
                {field: cityFields[ECityFields.coordinates]},
            ]
        },
    ],
    relatedEntities: [
        {
            title: "תושבים",
            entity: E_ENTITIES.contacts,
            query: (id:number)=>{
                return {
                    "table": Tables[Tables.DB_TABLE_CONTACT],
                    "conditions": [
                        {
                            "id": "",
                            "s": false,
                            "t": 1,
                            "l": 0,
                            "subject": "j_residence",
                            "filter": null,
                            "value": {
                                "join": "residence",
                                "innerQuery": {
                                    "conditions": [
                                        {
                                            "id": "",
                                            "s": false,
                                            "t": 1,
                                            "l": 0,
                                            "subject": "c_id",
                                            "filter": "c_in",
                                            "value": [id]
                                        }
                                    ],
                                    "table": Tables[Tables.DB_TABLE_CITY]
                                }
                            }
                        }
                    ]
                }
            }
        }
    ]
}

export interface ICity {
    id: number,
    name: string,
    aliases: string[],
    coordinates: {x:number, y:number} | null
    disabled: boolean
}

export const CityEntity:IEntity = {
    browsingPage: {
        suffix: "cities",
        title: "יישובים",
        newItemLabel: "יישוב חדש"
    },
    cardPage: {
        suffix: "city",
        title: "יישוב",
    },
    endpoint: "cities",
    fields: Object.values(cityFields),
    tableColumns: CityBrowserCols,
    cardLayout: CityCard,
    table: Tables.DB_TABLE_CITY
}