import React from 'react';
import "./ArrayManager.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import {cn} from "../../services/utils";

interface IProps {
	values: string[],
	labelDict?: {[key: string]: string}
	options?: string[],
	disabled?: boolean,
	readonly?: boolean,
	disableAdd?: boolean,
	disableEdit?: boolean,
	disableDelete?: boolean,
	onChange: Function
}

interface IState {}

export default class ArrayManager extends React.Component <IProps, IState> {
	constructor(props:IProps) {
		super(props);
		this.state = {
			values: props.values,
			options: props.options,
			onChange: props.onChange
		};
	}

	static getDerivedStateFromProps(nextProps:IProps, state:IState) {
		return {
			values: nextProps.values,
			options: nextProps.options,
			disabled: nextProps.disabled,
			onChange: nextProps.onChange
		};
	}

	removeEntry = (entryIndex:number) => {
		if(this.props.disabled || this.props.readonly || this.props.disableDelete){
			return;
		}
		const values = this.props.values.slice();
		if(entryIndex < 0 || entryIndex >= values.length){
			return;
		}
		values.splice(entryIndex, 1);
		if(this.props.onChange){
			this.props.onChange(values);
		}
	};

	private setEntryValue(value: string, entryIndex: number) {
		if(this.props.disabled || this.props.readonly || this.props.disableEdit){
			return;
		}
		const values = this.props.values.slice();
		if(entryIndex < 0 || entryIndex >= values.length){
			return;
		}
		values[entryIndex] = value;
		if(this.props.onChange){
			this.props.onChange(values);
		}
	}

	private addEntry() {
		if(this.props.disabled || this.props.readonly || this.props.disableAdd){
			return;
		}
		const values = this.props.values.slice();
		values.push("");
		if(this.props.onChange){
			this.props.onChange(values);
		}
	}

	render() {
		const values = this.props.values ? this.props.values.slice() : [];
		return (
			<div className={cn({"array-controller": true, "disabled": !!this.props.disabled})}>
				{
					values.map((v, i)=>{
						v = v ? v : "";
						return <div className={cn({"array-entry": true, "read-only": !!this.props.readonly})} key={"array-entry-"+i}>

							{
								(this.props.readonly || this.props.disableEdit)
									? <span>{this.props.labelDict ? this.props.labelDict[v] : v}</span>
									:
									<input type={"text"} value={v} style={{width: "" + (1 + v.length) + "ch"}}
										   autoFocus={true}
										   onChange={(e) => {
											   this.setEntryValue(e.target.value, i)
										   }}
										   disabled={this.props.disabled}
									/>
							}
							{
								this.props.readonly || this.props.disableDelete ? null
									: <button type="button" className={"remove-entry-button"} onClick={()=>{this.removeEntry(i)}}>
										<FontAwesomeIcon icon={faTimes}/>
									</button>
							}
						</div>
					})
				}
				{
					this.props.readonly || this.props.disableAdd ? null
						: <button disabled={this.props.disabled}
								  type="button" title={"בחירת ערכים"}
								  className={"add-entry-button"} onClick={()=>{this.addEntry()}}>
							<FontAwesomeIcon icon={faPlus}/>
						</button>
				}
			</div>
		)
	}
}
