import React from 'react';
import "./Dropdown.scss";

export default class Dropdown extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			value: props.value,
			id: props.id,
			options: props.options,
			onChange: props.onChange
		};
	}

	async componentDidMount() {
		if (!this.state.options.length) {
			const val = this.state.options[0].value;
			this.setState({value: val})
			this.state.onChange(val);
		}
	}

	handleSelect(val)
	{
		// Identify membership status dropdown to convert empty string to null 
		let optionsTexts = this.state.options.map(o => o.text)
		if (optionsTexts.includes('active') && optionsTexts.includes('cancelled')) {
			if (val === '') val = null
		}
		this.state.onChange(val);
		this.setState({value: val})
	}
	render() {
		let options = ''
		if (this.state.options.length) {
			options = this.state.options.map((opt, i) =>
				<option value={opt.value} key={opt.value}>
					{opt.text}
				</option>
			)
		}
		return (
			<select 
				className={this.props.className}
				disabled={this.props.userRole!=='admin'}
				id={this.state.id} 
				onChange={(e) => {this.handleSelect(e.target.value)}}
				value={this.state.value || ''}>
				{options}
			</select>
		)
	}
}
