import {inputType} from "./fieldControls";
import {fieldType, IField} from "./fields";

export const TABLE_COLS_INPUTS:{[key in fieldType]: inputType} = {
    [fieldType.NONE]: inputType.NONE,
    [fieldType.textType]: inputType.textInput,
    [fieldType.booleanType]: inputType.booleanInput,
    [fieldType.stringArray]: inputType.arrayManager,
    [fieldType.dateType]: inputType.dateInput,
    [fieldType.multipleOptionsType]: inputType.multiSelect,
    [fieldType.dropdown]: inputType.dropdown,
    [fieldType.jsonSchema]: inputType.jsonSchemaEditor,
    [fieldType.contactsQueryType]: inputType.contactsQueryEditor,
    [fieldType.contactCustomFields]: inputType.NONE,
    [fieldType.activityCustomFields]: inputType.NONE,
    [fieldType.entityType]: inputType.textInput,
    [fieldType.entitiesArrayType]: inputType.arrayManager,
    [fieldType.coordinatesType]: inputType.coordinatePicker,
}

export interface IEntityTableCol {
    field: IField,
    disabled?: boolean,
    readonly?: boolean,
    defaultWidth?: number,
}