import {IEntity} from "../entities";
import {Tables} from "../tables";
import {fieldType, IField} from "../fields";
import {IEntityTableCol} from "../tableColumns";
import {ICard} from "../cardControls";
import {E_ENTITIES} from "../entityDefinitions";

export interface IDataSource {
    id: number,
    create_date: Date,
    title: string,
    foreign_id?: string,
    activity_id: number,
    activity_title: string,
}

export enum EDataSourceFields {
    id, create_date,update_date, title, foreign_id, activity_id, activity_title
}

export const dataSourceFields:{[key in EDataSourceFields]: IField} = {
    [EDataSourceFields.id]:{
        field: "id",
        defaultVal: null,
        type: fieldType.textType,
        title: "id"
    },
    [EDataSourceFields.create_date]: {
        field: "create_date",
        defaultVal: () => { return (new Date()).toISOString() },
        type: fieldType.dateType,
        title: "תאריך יצירה"
    },
    [EDataSourceFields.update_date]: {
        field: "update_date",
        defaultVal: () => { return (new Date()).toISOString() },
        type: fieldType.dateType,
        title: "תאריך עדכון"
    },
    [EDataSourceFields.title]: {
        field: "title",
        defaultVal: "",
        type: fieldType.textType,
        validate: [validateTitleFormat],
        title: "שם מקור"
    },
    [EDataSourceFields.foreign_id]: {
        field: "foreign_id",
        defaultVal: "",
        type: fieldType.textType,
        validate: [validateForeignIdFormat],
        title: "מפתח (עבור שירותים חיצוניים)"
    },
    [EDataSourceFields.activity_id]: {
        field: "activity_id",
        defaultVal: "",
        type: fieldType.entityType,
        options: {entity: E_ENTITIES.activities},
        validate: [validateActivity],
        title: "פעילות"
    },
    [EDataSourceFields.activity_title]: {
        field: "activity_title",
        defaultVal: "",
        type: fieldType.textType,
        title: "פעילות"
    },
};

function validateTitleFormat(title:string) {
    const valid = title && title.length;
    return {valid: !!valid, err: valid ? undefined : "מזהה לשירותים חיצוניים לא תקין"}
}

function validateForeignIdFormat(title:string) {
    const valid = true;
    return {valid: !!valid, err: valid ? undefined : "מזהה לשירותים חיצוניים לא תקין"}
}

function validateActivity(activity:any) {
    const valid = !(activity === null || activity === undefined);
    return {valid: !!valid, err: valid ? undefined : "כל מקור מידע צריך להיות מזוהה עם פעילות"}
}

export const DataSourceBrowserCols:IEntityTableCol[] = [
    {
        field: dataSourceFields[EDataSourceFields.create_date],
        readonly: true,
        defaultWidth: 5,
    },
    {
        field: dataSourceFields[EDataSourceFields.title],
        readonly: true,
        defaultWidth: 10,
    },
    {
        field: dataSourceFields[EDataSourceFields.activity_title],
        readonly: true,
        defaultWidth: 10,
    },
    {
        field: dataSourceFields[EDataSourceFields.foreign_id],
        readonly: true,
        defaultWidth: 10,
    },
]

export const DataSourceCard:ICard = {
    sections: [
        {
            compact: true,
            hideOnNew: true,
            fields: [
                {
                    field: dataSourceFields[EDataSourceFields.id],
                    readonly: true
                },
                {
                    field: dataSourceFields[EDataSourceFields.create_date],
                    readonly: true
                },
                {
                    field: dataSourceFields[EDataSourceFields.update_date],
                    readonly: true
                }
            ]
        },
        {
            fields: [
                {field: dataSourceFields[EDataSourceFields.title]},
                {field: dataSourceFields[EDataSourceFields.activity_id]},
                {field: dataSourceFields[EDataSourceFields.foreign_id]},
            ]
        },
    ],
    relatedEntities: [
        {
            title: "אנשי קשר שהופיעו במקור המידע הזה",
            entity: E_ENTITIES.contacts,
            query: (id:number)=> {
                return {
                    "conditions": [
                        {
                            "filter": null,
                            "id": "",
                            "l": 0,
                            "s": false,
                            "subject": "j_inclusion_in_datasource",
                            "t": 1,
                            "value": {
                                "join": "inclusion_in_datasource",
                                "innerQuery": {
                                    "conditions": [
                                        {
                                            "filter": "ds_in",
                                            "id": "",
                                            "l": 0,
                                            "s": false,
                                            "subject": "c_id",
                                            "t": 1,
                                            "value": [ id ]
                                        }
                                    ],
                                    "table": Tables[Tables.DB_TABLE_DATASOURCE]
                                }
                            }
                        }
                    ],
                    "table": Tables[Tables.DB_TABLE_CONTACT]
                }
            }
        },
    ]
}

export const DataSourceEntity:IEntity = {
    browsingPage: {
        suffix: "data-sources",
        title: "מקורות נתונים",
        newItemLabel: "מקור חדש"
    },
    cardPage: {
        suffix: "data-source",
        title: "מקור נתונים",
    },
    endpoint: "dataSources",
    fields: Object.values(dataSourceFields),
    table: Tables.DB_TABLE_DATASOURCE,
    tableColumns: DataSourceBrowserCols,
    cardLayout: DataSourceCard
}