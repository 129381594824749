import * as React from "react";
import EntityPicker from "../TableComponents/EntityPicker/EntityPicker";
import DatePicker from 'react-date-picker';
import ArrayManager from "../ArrayManager/ArrayManager";
import CoordinateSelector from "../CoordinateSelector/CoordinateSelector";
import CustomContactFieldsQueryEditor from "../JSONQueryEditor/CustomContactFieldsQueryEditor";

import {E_ENTITIES} from "../../services/dataManagement/entityDefinitions";
import MultiEntityPicker from "../TableComponents/EntityPicker/MultiEntityPicker";
import Dropdown from "../Dropdown/Dropdown";

export type IDBColumnFilter = {
    key: string,
    label: string,
    inputTemplate: (onChange: Function, onSubmit: Function, value: any, options?: any) => JSX.Element
}

export type IDBColumnType = {
    availableFilters: {[key: string]: IDBColumnFilter}
}

export enum EDBColumnTypes {
    STRING_COL, DATE_COL, NUMBER_COL, POINT_COL,
    JSON_COL, CONTACT_CUSTOM_FIELDS_VAL_COL, ACTIVITY_CUSTOM_FIELDS_VAL_COL,
    CITY_COL, ACTIVITY_COL, ACTIVITY_TYPE_COL, DATASOURCE_COL,
    CONTACT_COL, ACTIVITY_TAG_COL, CONTACT_GROUP_COL, MEMBERSHIP_STATUS_COL,
    CONTACT_CUSTOM_FIELDS_COL, ACTIVITY_CUSTOM_FIELDS_COL, REQUESTED_NO_SPAM_COL,
    USER_COL
}

export type IDBColumnTypeDict = { [key in EDBColumnTypes]: IDBColumnType }

export const ColumnTypeDict: IDBColumnTypeDict = {
    [EDBColumnTypes.STRING_COL]: {
        availableFilters: {
            s_eq:{
                key: "s_eq",
                label: "שווה בדיוק ל",
                inputTemplate: (onChange: Function, onSubmit: Function, value: any) => {
                    return <input
                        className={"filter-input-wrap"} type="text" value={value ? value : ""}
                        onChange={(e) => onChange(e.target.value)}
                        onKeyDown={(e)=>{return (e.key === 'Enter') ? onSubmit() : null}}
                    />
                }
            },
            s_inc:{
                key: "s_inc",
                label: "מכיל",
                inputTemplate: (onChange: Function, onSubmit: Function, value: any) => {
                    return <input
                        className={"filter-input-wrap"} type="text" value={value ? value : ""}
                        onChange={(e) => onChange(e.target.value)}
                        onKeyDown={(e)=>{return (e.key === 'Enter') ? onSubmit() : null}}
                    />
                }
            },
            s_in:{
                key: "s_in",
                label: "אחד מבין",
                inputTemplate: (onChange: Function, onSubmit: Function, value: any) => {
                    return <span className={"filter-input-wrap"}>
                        <ArrayManager values={value ? value : []} onChange={(v: any) => onChange(v)}/>
                    </span>
                }
            }
        }
    },
    [EDBColumnTypes.NUMBER_COL]: {
        availableFilters: {
            n_eq:{
                key: "n_eq",
                label: "שווה בדיוק ל",
                inputTemplate: (onChange: Function, onSubmit: Function, value: any) => {
                    return <input
                        type="number"
                        className={"filter-input-wrap"}
                        value={value ? value : 0}
                        onChange={(e) => onChange(e.target.value)}
                        onKeyDown={(e)=>{return (e.key === 'Enter') ? onSubmit() : null}}
                    />
                }
            },
            n_gt:{
                key: "n_gt",
                label: "גדול מ",
                inputTemplate: (onChange: Function, onSubmit: Function, value: any) => {
                    return <input
                        type="number"
                        className={"filter-input-wrap"}
                        value={value ? value : 0}
                        onChange={(e) => onChange(e.target.value)}
                        onKeyDown={(e)=>{return (e.key === 'Enter') ? onSubmit() : null}}
                    />
                }
            },
            n_gte:{
                key: "n_gte",
                label: "גדול שווה ל",
                inputTemplate: (onChange: Function, onSubmit: Function, value: any) => {
                    return <input
                        type="number"
                        className={"filter-input-wrap"}
                        value={value ? value : 0}
                        onChange={(e) => onChange(e.target.value)}
                        onKeyDown={(e)=>{return (e.key === 'Enter') ? onSubmit() : null}}
                    />
                }
            },
            n_lt:{
                key: "n_lt",
                label: "קטן מ",
                inputTemplate: (onChange: Function, onSubmit: Function, value: any) => {
                    return <input
                        type="number"
                        className={"filter-input-wrap"}
                        value={value ? value : 0}
                        onChange={(e) => onChange(e.target.value)}
                        onKeyDown={(e)=>{return (e.key === 'Enter') ? onSubmit() : null}}
                    />
                }
            },
            n_lte:{
                key: "n_lte",
                label: "קטן שווה ל",
                inputTemplate: (onChange: Function, onSubmit: Function, value: any) => {
                    return <input
                        type="number"
                        className={"filter-input-wrap"}
                        value={value ? value : 0}
                        onChange={(e) => onChange(e.target.value)}
                        onKeyDown={(e)=>{return (e.key === 'Enter') ? onSubmit() : null}}
                    />
                }
            },
            n_bet:{
                key: "n_bet",
                label: "בטווח",
                inputTemplate: (onChange: Function, onSubmit: Function, value: any) => {
                    return <div>
                        <span>בין</span>
                        <input
                            type="number" className={"filter-input-wrap"} value={value[0] ? value[0] : 0}
                            onChange={(e) => {
                                value[0] = e.target.value;
                                onChange(value);
                            }}
                            onKeyDown={(e)=>{return (e.key === 'Enter') ? onSubmit() : null}}
                        />
                        <span>לבין</span>
                        <input
                            type="number" className={"filter-input-wrap"} value={value[1] ? value[1] : 0}
                            onChange={(e) => {
                                value[1] = e.target.value;
                                onChange(value);
                            }}
                            onKeyDown={(e)=>{return (e.key === 'Enter') ? onSubmit() : null}}
                        />
                    </div>
                }
            }
        }
    },
    [EDBColumnTypes.DATE_COL]: {
        availableFilters: {
            d_eq:{
                key: "d_eq",
                label: "שווה בדיוק ל",
                inputTemplate: (onChange: Function, onSubmit: Function, value: any) => {
                    return <span dir="ltr" className={"filter-input-wrap"}>
                        <DatePicker
                            value={value}
                            onChange={async (e: any) => {
                                await onChange(e);
                                await onSubmit();
                            }}
                            disableCalendar={true} clearIcon = {undefined} maxDate={undefined} format="d-M-yy"
                        />
                    </span>
                }
            },
            d_aft:{
                key: "d_aft",
                label: "אחרי",
                inputTemplate: (onChange: Function, onSubmit: Function, value: any) => {
                    return <span dir="ltr" className={"filter-input-wrap"}>
                        <DatePicker
                            value={value}
                            onChange={async (e: any) => {
                                await onChange(e);
                                await onSubmit();
                            }}
                            disableCalendar={true} clearIcon = {undefined} maxDate={undefined} format="d-M-yy"
                        />
                    </span>
                }
            },
            d_bef:{
                key: "d_bef",
                label: "לפני",
                inputTemplate: (onChange: Function, onSubmit: Function, value: any) => {
                    return <span dir="ltr" className={"filter-input-wrap"}>
                        <DatePicker
                            value={value}
                            onChange={async (e: any) => {
                                await onChange(e);
                                await onSubmit();
                            }}
                            disableCalendar={true} clearIcon = {undefined} maxDate={undefined} format="d-M-yy"
                        />
                    </span>
                }
            },
            d_bet:{
                key: "d_bet",
                label: "בטווח",
                inputTemplate: (onChange: Function, onSubmit: Function, value: any) => {
                    return <div>
                        <span className={"inline-condition-input-label"}>בין</span>
                        <span dir="ltr">
                            <DatePicker
                                className={"filter-input-wrap"} value={value ? value[0] : null}
                                onChange={async (e: any) => {
                                    value = value ? value : {};
                                    value[0] = e;
                                    await onChange(value);
                                    await onSubmit();
                                }}
                                disableCalendar={true} clearIcon = {undefined} maxDate={undefined} format="d-M-yy"
                            />
                        </span>
                        <span className={"inline-condition-input-label"}>לבין</span>
                        <span dir="ltr">
                            <DatePicker
                                className={"filter-input-wrap"}
                                value={value ? value[1] : null}
                                onChange={async (e: any) => {
                                    value = value ? value : {};
                                    value[1] = e;
                                    await onChange(value);
                                    await onSubmit();
                                }}
                                disableCalendar={true} clearIcon = {undefined} maxDate={undefined} format="d-M-yy"
                        />
                        </span>
                    </div>
                }
            }
        }
    },
    [EDBColumnTypes.CITY_COL]: {
        availableFilters: {
            c_in:{
                key: "c_in",
                label: "אחת מבין",
                inputTemplate: (onChange: Function, onSubmit: Function, value: any) => {
                    return <MultiEntityPicker
                        entity={E_ENTITIES.cities}
                        onSelect={async (v:number[])=>{
                            await onChange(v);
                            await onSubmit();
                        }}
                        selected={value ? value : []}
                        embedInPopup={true}
                        hideQueryEditor={true}
                        hideFreeSearch={false}
                    />
                }
            },
        }
    },
    [EDBColumnTypes.ACTIVITY_TAG_COL]: {
        availableFilters: {
            at_in:{
                key: "at_in",
                label: "אחד מבין",
                inputTemplate: (onChange: Function, onSubmit: Function, value: any) => {
                    return <MultiEntityPicker
                        entity={E_ENTITIES.activityTags}
                        onSelect={async (v:number[])=>{
                            await onChange(v);
                            await onSubmit();
                        }}
                        selected={value ? value : []}
                        embedInPopup={true}
                        hideQueryEditor={true}
                        hideFreeSearch={false}
                    />
                }
            },
        }
    },
    [EDBColumnTypes.MEMBERSHIP_STATUS_COL]: {
        availableFilters: {
            "ms_is":{
                key: "ms_is",
                label: "שווה ל",
                inputTemplate: (onChange: Function, onSubmit: Function, value: any) => {
                    return <Dropdown
                        className="condition-filter-selector"
                        onChange={async (v:any)=>{
                            await onChange(v);
                            await onSubmit();
                        }}
                        userRole='admin'
                        options= {[
                            {
                                value: "active",
                                text: "active"
                            },
                            {
                                value: "cancelled",
                                text: "cancelled"
                            },
                            {
                                value: "",
                                text: "none"
                            },
                        ]}
                        value={value}
                    />
                }
            },
        }
    },
    [EDBColumnTypes.REQUESTED_NO_SPAM_COL]: {
        availableFilters: {
            "rns_is":{
                key: "rns_is",
                label: "שווה ל",
                inputTemplate: (onChange: Function, onSubmit: Function, value: any) => {
                    return <Dropdown
                        className="condition-filter-selector"
                        onChange={async (v:any)=>{
                            await onChange(v);
                            await onSubmit();
                        }}
                        userRole='admin'
                        options= {[
                            {
                                value: true,
                                text: "כן"
                            },
                            {
                                value: false,
                                text: "לא"
                            },
                        ]}
                        value={value}
                    />
                }
            },
        }
    },
    [EDBColumnTypes.ACTIVITY_COL]: {
        availableFilters: {
            ac_in:{
                key: "ac_in",
                label: "אחת מבין",
                inputTemplate: (onChange: Function, onSubmit: Function, value: any) => {
                    return <MultiEntityPicker
                        entity={E_ENTITIES.activities}
                        onSelect={async (v:number[])=>{
                            await onChange(v);
                            await onSubmit();
                        }}
                        selected={value ? value : []}
                        embedInPopup={true}
                        hideQueryEditor={true}
                        hideFreeSearch={false}
                    />
                }
            },
        }
    },
    [EDBColumnTypes.CONTACT_GROUP_COL]: {
        availableFilters: {
            cg_in:{
                key: "cg_in",
                label: "חברות בקבוצה",
                inputTemplate: (onChange: Function, onSubmit: Function, value: any) => {
                    return <MultiEntityPicker
                        entity={E_ENTITIES.contactGroups}
                        onSelect={async (v:number[])=>{
                            await onChange(v);
                            await onSubmit();
                        }}
                        selected={value ? value : []}
                        embedInPopup={true}
                        hideQueryEditor={true}
                        hideFreeSearch={false}
                    />
                }
            },
        }
    },
    [EDBColumnTypes.CONTACT_COL]: {
        availableFilters: {
            co_in:{
                key: "co_in",
                label: "אחד מבין",
                inputTemplate: (onChange: Function, onSubmit: Function, value: any) => {
                    return <MultiEntityPicker
                        entity={E_ENTITIES.contacts}
                        onSelect={async (v:number[])=>{
                            await onChange(v);
                            await onSubmit();
                        }}
                        selected={value ? value : []}
                        embedInPopup={true}
                        hideQueryEditor={true}
                        hideFreeSearch={false}
                    />
                }
            },
        }
    },
    [EDBColumnTypes.POINT_COL]: {
        availableFilters: {
            co_within: {
                key: "co_within",
                label: "בתוך",
                inputTemplate: (onChange: Function, onSubmit: Function, value: any) => {
                    return <CoordinateSelector
                        embedInPopup
                        polygons={value}
                        onChange={async (v:number[])=>{
                            await onChange(v);
                            await onSubmit();
                        }}
                    />
                }
            },
        }
    },
    [EDBColumnTypes.CONTACT_CUSTOM_FIELDS_VAL_COL]: {
        availableFilters: {
            json_property_filter: {
                key: "json_property_filter",
                label: "סינון שדה",
                inputTemplate: (onChange: Function, onSubmit: Function, value: any) => {
                    return <CustomContactFieldsQueryEditor
                        data={value ? value.data : null}
                        filter={value ? value.filter : null}
                        onFilterChange={(v) => {
                            value = value ? value : {};
                            value.filter = v;
                            onChange(value);
                        }}
                        onChange={async (v) => {
                            value = value ? value : {};
                            value.data = v;
                            onChange(value);
                        }}
                        onSubmit={()=>{onSubmit()}}
                    />
                }
            },
        }
    },
    [EDBColumnTypes.ACTIVITY_CUSTOM_FIELDS_VAL_COL]: {
        availableFilters: {
            json_property_filter: {
                key: "json_property_filter",
                label: "סינון שדה",
                inputTemplate: (onChange: Function, onSubmit: Function, value: any) => {
                    return <CustomContactFieldsQueryEditor
                        data={value ? value.data : null}
                        filter={value ? value.filter : null}
                        onFilterChange={(v) => {
                            value = value ? value : {};
                            value.filter = v;
                            onChange(value);
                        }}
                        onChange={async (v) => {
                            value = value ? value : {};
                            value.data = v;
                            onChange(value);
                        }}
                        onSubmit={()=>{onSubmit()}}
                    />
                }
            },
        }
    },
    [EDBColumnTypes.USER_COL]: {
        availableFilters: {
            u_in:{
                key: "u_in",
                label: "אחד מבין",
                inputTemplate: (onChange: Function, onSubmit: Function, value: any) => {
                    return <MultiEntityPicker
                        entity={E_ENTITIES.users}
                        onSelect={async (v:number[])=>{
                            await onChange(v);
                            await onSubmit();
                        }}
                        selected={value ? value : []}
                        embedInPopup={true}
                        hideQueryEditor={true}
                        hideFreeSearch={false}
                    />
                }
            },
        }
    },
    [EDBColumnTypes.ACTIVITY_TYPE_COL]: {
        availableFilters: {
            aty_in:{
                key: "aty_in",
                label: "אחת מבין",
                inputTemplate: (onChange: Function, onSubmit: Function, value: any) => {
                    return <MultiEntityPicker
                        entity={E_ENTITIES.activityTypes}
                        onSelect={async (v:number[])=>{
                            await onChange(v);
                            await onSubmit();
                        }}
                        selected={value ? value : []}
                        embedInPopup={true}
                        hideQueryEditor={true}
                        hideFreeSearch={false}
                    />
                }
            },
        }
    },
    [EDBColumnTypes.DATASOURCE_COL]: {
        availableFilters: {
            ds_in:{
                key: "ds_in",
                label: "אחד מבין",
                inputTemplate: (onChange: Function, onSubmit: Function, value: any) => {
                    return <MultiEntityPicker
                        entity={E_ENTITIES.dataSources}
                        onSelect={async (v:number[])=>{
                            await onChange(v);
                            await onSubmit();
                        }}
                        selected={value ? value : []}
                        embedInPopup={true}
                        hideQueryEditor={true}
                        hideFreeSearch={false}
                    />
                }
            },
        }
    },
    [EDBColumnTypes.CONTACT_CUSTOM_FIELDS_COL]: {
        availableFilters: {
            ccf_in:{
                key: "ccf_in",
                label: "אחד מבין",
                inputTemplate: (onChange: Function, onSubmit: Function, value: any) => {
                    return <MultiEntityPicker
                        entity={E_ENTITIES.customContactFields}
                        onSelect={async (v:number[])=>{
                            await onChange(v);
                            await onSubmit();
                        }}
                        selected={value ? value : []}
                        embedInPopup={true}
                        hideQueryEditor={true}
                        hideFreeSearch={false}
                    />
                }
            },
        }
    },
    [EDBColumnTypes.ACTIVITY_CUSTOM_FIELDS_COL]: {
        availableFilters: {
            acf_in:{
                key: "acf_in",
                label: "אחד מבין",
                inputTemplate: (onChange: Function, onSubmit: Function, value: any) => {
                    return <MultiEntityPicker
                        entity={E_ENTITIES.customActivityFields}
                        onSelect={async (v:number[])=>{
                            await onChange(v);
                            await onSubmit();
                        }}
                        selected={value ? value : []}
                        embedInPopup={true}
                        hideQueryEditor={true}
                        hideFreeSearch={false}
                    />
                }
            },
        }
    },
    [EDBColumnTypes.JSON_COL]: {
        availableFilters: {
            co_in:{
                key: "co_in",
                label: "אחד מבין",
                inputTemplate: (onChange: Function, onSubmit: Function, value: any) => {
                    return <div>Not Implemented Yet!</div>
                }
            },
        }
    },
};
