import React from 'react';
import "./CitySearch.scss";
import server from "../../services/server";
import {cn} from "../../services/utils";
import LoadSpinner from "../LoadSpinner/LoadSpinner";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
const customID =  require("custom-id");

interface ISuggestion {
	id: number,
	suggestion: string
}

interface IProps {
	value: number | null,
	disabled?: boolean,
	onChange: Function,
}

interface IState {
	uniqueId: string,
	value: number | null,
	input: string,
	suggestions: ISuggestion[],
	disabled?: boolean,
	onChange: Function,
	loading: boolean;
}
export default class CitySearch extends React.Component <IProps, IState> {
	constructor(props:IProps) {
		super(props);
		this.state = {
			uniqueId: customID({}),
			value: props.value,
			input: "",
			suggestions: [],
			disabled: props.disabled,
			onChange: props.onChange,
			loading: false
		};
	}

	async componentDidMount() {
		if(this.state.value !== null && this.state.value !== undefined){
			await this.setCityNameBasedOnId();
		}
	}

	static getDerivedStateFromProps(nextProps:IProps, state:IState) {
		return {
			value: nextProps.value,
			disabled: nextProps.disabled,
			onChange: nextProps.onChange
		};
	}

	static async fetchCityName (cityId:number) {
		const city:{aliases?: string[], error?: any} = await server.get('cities/' + cityId);
		if(city && !city.error && city.aliases && city.aliases[0]){
			return city.aliases[0]
		}
		return "";
	};

	setCityNameBasedOnId = async () => {
		if(!this.state.value){
			return "";
		}
		const name = await CitySearch.fetchCityName(this.state.value);
		this.setState({input: name})
	};

	handleChange = async (input: string) => {
		if(!input || !input.length){
			this.setState({input});
			return;
		}
		this.setState({input, loading: true});
		const suggestions = ((await server.get('cities/search/' + input)).suggestions as ISuggestion[]);
		let value: number | null = null;
		suggestions.forEach((s, i:number)=>{
			if(s.suggestion === input){
				value = s.id;
			}
		});
		this.setState({suggestions, value, loading: false}, ()=>{
			this.state.onChange(value)
		})
	};

	addCity = async () => {
		const name = this.state.input;
		if(!name || !name.length || this.state.loading){
			return;
		}
		this.setState({loading: true}, async ()=>{
			const addCityResult = await server.post('cities/add', {name});
			if("id" in addCityResult){
				this.state.onChange(addCityResult.id);
			}
			this.setState({loading: false})
		})
	};

	render() {
		const suggestions = <datalist id={this.state.uniqueId}>{
			this.state.suggestions.map((s, i:number)=>{
				return <option value={s.suggestion} key={s.suggestion}/>
			})
		}</datalist>;
		return (
			<div className={"city-search-wrap"}>
				{suggestions}
				<input type={"text"}
					   list={this.state.uniqueId}
					   value={this.state.input}
					   onChange={async (e)=>{await this.handleChange(e.target.value)}}
					   className={cn({"city-input": true, "valid-value": this.state.value !== null})}
				/>
				{this.state.loading ? <LoadSpinner visibility={true} radius={1}/> : null}
				{(this.state.value === null && !this.state.loading) ?
					<button
						type={"button"}
						onClick={async () => {await this.addCity()}}
						className={"add-city-button"}
					>
						<FontAwesomeIcon icon={faPlus}/>
					</button> : null}
			</div>
		)
	}
}
