import React from 'react';
import server from "../../services/server";
import LoadSpinner from "../LoadSpinner/LoadSpinner";

interface IProps{
    onChange: (id:string)=>void,
    value?: string
}

interface IState{
    onChange: (id:string)=>void,
    value?: string,
    lists?: IMailchimpAudience[]
}

interface IMailchimpAudience{
    id: string,
    name: string,
    stats: {member_count: number}
}

export default class MailchimpListSelector extends React.Component <IProps, IState> {
    constructor(props:IProps) {
        super(props);
        this.state = {
            value: props.value,
            onChange: props.onChange,
        };
    }

    static getDerivedStateFromProps(nextProps:IProps, state:IState) {
        return {
            value: nextProps.value,
        };
    }

    componentDidMount() {
        this.getMailchimpLists()
    }

    getMailchimpLists() {
        server.get('mailchimp/lists/')
            .then((mailchimpLists: { lists: IMailchimpAudience[] }) => {
                if(mailchimpLists.lists) {
                    const lists = mailchimpLists.lists.sort(
                        (a,b) => {return b.stats.member_count - a.stats.member_count}
                    )
                    this.setState({lists}, ()=>{
                        if(lists.length){
                            this.onChange(lists[0].id)
                        }
                    });
                }
            });
    }

    onChange = (value:string)=>{
        this.state.onChange(value);
    }

    render() {
        const lists = this.state.lists ? this.state.lists.slice() : null;
        return <div>{
            lists ? <select value={this.state.value} onChange={(e)=>this.onChange(e.target.value)}>
                {

                    lists.map((l, i)=>{
                        return <option key={"mailchimp_list"+i} value={l.id}>{
                            l.name
                        }</option>
                    })
                }
            </select>
            : <LoadSpinner visibility={true} radius={2}/>
        }
        </div>
    }
}
