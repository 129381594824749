import {Tables} from "./tables";
import React from "react";
import {IEntityBrowsingPage, IEntityCardPage} from "./pages";
import {ContactGroupEntity} from "./entities/contactGroups";
import {ActivityEntity} from "./entities/activity";
import {ContactCustomFieldEntity} from "./entities/contactCustomFields";
import {ActivityCustomFieldEntity} from "./entities/activityCustomFields";
import {ActivityTypeEntity} from "./entities/activityType";
import {CityEntity} from "./entities/city";
import {DataSourceEntity} from "./entities/dataSource";
import {ContactEntity} from "./entities/contact";
import {UserEntity} from "./entities/users";
import {IDBQuery} from "../../UIComponents/QueryBuilder/QueryBuilder";
import {IField} from "./fields";
import {IEntityTableCol} from "./tableColumns";
import {ICard} from "./cardControls";
import {E_ENTITIES} from "./entityDefinitions";
import {ActivityTagEntity} from "./entities/activityTag";


export interface IEntity {
    browsingPage: IEntityBrowsingPage,
    cardPage: IEntityCardPage,
    cardLayout?: ICard,
    table: Tables,
    endpoint: string,
    fields: IField[],
    tableColumns: IEntityTableCol[],
    browserActions?: ((query: IDBQuery, searchTerm: string, currPage: number) => JSX.Element)[],
}

export const ENTITIES:{[key in E_ENTITIES]: IEntity} = {
    [E_ENTITIES.contactGroups]: ContactGroupEntity,
    [E_ENTITIES.activityTags]: ActivityTagEntity,
    [E_ENTITIES.customContactFields]: ContactCustomFieldEntity,
    [E_ENTITIES.customActivityFields]: ActivityCustomFieldEntity,
    [E_ENTITIES.activityTypes]: ActivityTypeEntity,
    [E_ENTITIES.cities]: CityEntity,
    [E_ENTITIES.dataSources]: DataSourceEntity,
    [E_ENTITIES.contacts]: ContactEntity,
    [E_ENTITIES.activities]: ActivityEntity,
    [E_ENTITIES.users]: UserEntity,
}