import React, { useReducer } from 'react'
import './EntityCard.scss';
import { role, IHasId } from "../../services/interfaces";
import { ENTITIES } from "../../services/dataManagement/entities";
import { CARD_INPUTS } from "../../services/dataManagement/cardControls";
import { FIELD_CONTROLS, FIELD_JUSTIFICATION } from "../../services/dataManagement/fieldControls";
import LoadSpinner from "../LoadSpinner/LoadSpinner";
import ApprovalPopup from "../ApprovalPopup/ApprovalPopup";
import server, { HTTP_METHODS } from "../../services/server";
import { cn } from "../../services/utils";
import EntityBrowser from "../TableComponents/EntityBrowser/EntityBrowser";
import { E_ENTITIES } from "../../services/dataManagement/entityDefinitions";

export interface ISavingError {

}

interface IProps {
    id: null | number,
    entity: E_ENTITIES,
	navigate: any,
    userRole: role,
    onLoad?: (data: any) => any,
    onDelete?: (id?: number) => any,
    onSave?: (id: number) => any,
    onSaveError?: (errors: ISavingError[]) => any,
}
interface IState<Entity> {
    loading: boolean,
    data: null | any,
    errors: string[],
    valid: boolean,
    saveLoading: boolean,
    saveSuccessful: boolean,
    saveFailed: boolean,
    saveErrors: string[] | null,
    lastUpdated: number
}

export default (class EntityCard<Entity extends IHasId> extends React.Component<IProps, IState<Entity>> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            loading: false,
            valid: false,
            data: null,
            errors: [],
            saveLoading: false,
            saveSuccessful: false,
            saveFailed: false,
            saveErrors: [],
            lastUpdated: new Date().getTime()
        };
    }

    async componentDidMount() {
        const id = this.props.id;
        if (id === null) {
            const newItem: { [key: string]: any } = {};
            const entity = ENTITIES[this.props.entity];
            entity.fields.forEach(f => {
                newItem[f.field] = typeof f.defaultVal === 'function' ? f.defaultVal() : f.defaultVal
            })
            this.setState({
                data: newItem
            })
        }
        await this.fetchData();
    }

    fetchData = async () => {
        const id = this.props.id;
        if (id === null) {
            return;
        }
        const entity = ENTITIES[this.props.entity];
        const res = await server.get("./" + entity.endpoint + "/" + id);
        if ("error" in res) {
            console.log(res.error)
        }
        else {
            this.setState({ data: res }, () => {
                return this.props.onLoad ? this.props.onLoad(res) : null;
            })
        }
    }

	goToItem = (id: null | number) => {
		if (this.props.navigate) this.props.navigate("/" + ENTITIES[this.props.entity].cardPage.suffix + "/" + ((id === null) ? "": "?id=" + id));
	};

    render() {
        const id = this.props.id;
        const entity = ENTITIES[this.props.entity];
        const data = this.state.data;
        return (
            <div dir="rtl">
                {data ? <div className={"entity-card-wrap"}>
                    {
                        entity.cardLayout ? entity.cardLayout.sections.map((s, i) => {
                            return (s.hideOnNew && id === null) ? null :
                                <div key={"section_" + i} className={"entity-card-section"}>
                                    {s.title ? <div className={"entity-card-section-title"}>{s.title}</div> : null}
                                    <div className={cn({
                                        "entity-card-section-fields": true,
                                        "entity-card-section-compact": !!s.compact
                                    })}>
                                        {s.fields.map((f) => {
                                            return <div key={"f_" + f.field.field} className={"card-field-wrap"}>
                                                <span className={"card-field-title"}>{f.field.title}</span>
                                                <span className={"card-field"}>
                                                    {
                                                        FIELD_CONTROLS[CARD_INPUTS[f.field.type]]({
                                                            readonly: !!f.readonly,
                                                            disabled: !!f.disabled,
                                                            dir: f.field.dir ? f.field.dir : FIELD_JUSTIFICATION.AUTO,
                                                            value: data[f.field.field],
                                                            options: f.field.options ? f.field.options : {},
                                                            userRole: this.props.userRole,
                                                            onChange: (v: any) => { this.setFieldValue(f.field.field, v) }
                                                        })
                                                    }
                                                </span>
                                            </div>
                                        })}
                                    </div>
                                    <hr />
                                </div>
                        }) : null}
                    <span className={"vertical-spread"}>
                        <button type={"button"}
                            onClick={async () => { await this.save() }}
                            className={"big-button purple-background"}
                            disabled={!this.state.valid}
                            title={this.state.errors.length ? this.state.errors.join(", ") : "שמירה"}
                        >
                            {
                                this.state.saveLoading ?
                                    <LoadSpinner visibility={true} radius={2} /> :
                                    this.state.saveFailed ?
                                        <span
                                            title={this.state.saveErrors ? this.state.saveErrors.join(", ") : "שגיאה בשמירה"}>
                                            שגיאה בשמירה
                                        </span> :
                                        this.state.saveSuccessful ?
                                            <span>הרשומה נשמרה</span> :
                                            <span>שמירה</span>
                            }
                        </button>
                        {
                            this.state.errors.length ?
                                <ul className="errors-list">
                                    {
                                        this.state.errors.map(err => {
                                            return <li className="error">{err}</li>
                                        })
                                    }
                                </ul> : null

                        }
                        {
                            (this.props.id !== null && this.props.userRole === 'admin' as role) ?
                                <ApprovalPopup
                                    onApprove={async () => { await this.deleteItem() }}
                                    title={"מחיקת פריט"}
                                    description={"שימו לב - המחיקה תתבצע מגראמשי, אבל לא ממערכות מקושרות. לא ניתן לבטל מחיקה."}
                                    buttonClassNames={["big-button", "red-button"]}
                                    buttonLabel={(id !== null) ? "מחיקה" : "ביטול"}
                                    requireTyping={"DELETE"}
                                /> : null
                        }
                    </span>
                    <hr />
                    {
                        id !== null && entity.cardLayout && entity.cardLayout.relatedEntities ?
                            entity.cardLayout.relatedEntities.map(re => {
                                return <div key={"related_" + re.title + this.state.lastUpdated}>
                                    <EntityBrowser
                                        entity={re.entity}
                                        query={re.query(id, data)}
                                        hideFreeSearch={true}
                                        hideQueryEditor={true}
                                        hideResultCounter={true}
                                        menuTitle={re.title}
                                        linkToPage={true}
                                        onRowClick={(id: number) => {
                                            this.goToItem(id)
                                        }}
                                    />
                                    <hr />
                                </div>
                            })
                            : null
                    }
                </div> : <LoadSpinner visibility={true} />}
            </div>
        )
    }

    private async deleteItem() {
        const entity = ENTITIES[this.props.entity];
        const data = this.state.data;
        let res = await server.post(`${entity.endpoint}/${data.id}`, {}, HTTP_METHODS.delete);
        if (res === true) {
            window.alert("הפריט נמחק");
            window.history.back();
        } else window.alert(`תקלה במחיקת הפריט: ${res}`);
    }

    private validate() {
        const entity = ENTITIES[this.props.entity];
        const data = this.state.data;
        let valid = true;
        let errors: string[] = [];
        entity.fields.forEach(f => {
            if (f.validate) {
                f.validate.forEach(test => {
                    const validationCheck = test(data[f.field]);
                    valid = valid && validationCheck.valid;
                    if (validationCheck.err) {
                        errors.push(validationCheck.err)
                    }
                })
            }
        })
        this.setState({ errors, valid })
        return valid;
    }

    private async save() {
        if (this.state.saveLoading) {
            return;
        }
        const entity = ENTITIES[this.props.entity];
        if (this.validate()) {
            this.setState({ saveLoading: true, saveSuccessful: false, saveFailed: false, saveErrors: [] },
                async () => {
                    const data = this.state.data;
                    const saveResult = await server.post(entity.endpoint, data);
                    if ("error" in saveResult) {
                        this.setState({ saveLoading: false, saveSuccessful: false, saveFailed: true, saveErrors: [saveResult.error] })
                        if (this.props.onSaveError) {
                            this.props.onSaveError(saveResult.error);
                        }
                    }
                    else {
                        this.setState({ saveLoading: false, saveSuccessful: true, saveFailed: false, saveErrors: [] })
                        if (this.props.onSave) {
                            this.props.onSave(saveResult.id);
                        }
                    }
                })
            setTimeout(_ => {
                this.setState({ lastUpdated: new Date().getTime() })
            }, 100)
        }
    }

    private setFieldValue(field: string, v: any) {
        const data = this.state.data || {};
        data[field] = v;
        this.setState({ data, saveSuccessful: false, saveFailed: false, saveErrors: [] }, () => {
            this.validate();
        });
    }
}
)