import React from 'react'
import server from '../../../services/server'
import './EntityBrowser.scss';
import QueryBuilder, {IDBQuery} from "../../../UIComponents/QueryBuilder/QueryBuilder";
import {Tables} from "../../../services/dataManagement/tables";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowUpRightFromSquare, faRefresh} from "@fortawesome/free-solid-svg-icons";
import PageNav from "../../../UIComponents/PageNav/PageNav";
import SearchBar from '../../../UIComponents/SearchBar/SearchBar';
import LoadSpinner from "../../LoadSpinner/LoadSpinner";
import FileSaver from 'file-saver';
import {IHasId} from "../../../services/interfaces";
import {ENTITIES} from "../../../services/dataManagement/entities";
import ClickableTable from "../ClickableTable/ClickableTable";
import {E_ENTITIES} from "../../../services/dataManagement/entityDefinitions";


const rison = require('rison-node');


interface IProps {
    entity: E_ENTITIES
    currPage?: number,
    searchTerm?: string,
    query?: IDBQuery,
    onQueryChange?: (query:IDBQuery)=>void,
    onPageChange?: (p:number)=>void,
    onSearchTermChange?: (s:string)=>void,
    hideExport?: boolean,
    hideQueryEditor?: boolean,
    hideResultCounter?: boolean,
    hideFreeSearch?: boolean,
    menuTitle?: string,
    onRowClick?: (id:number)=>void,
    selected?: number[],
    linkToPage?: boolean
}
interface IState<Entity> {
    query: IDBQuery,
    currPage: number,
    pageCount: number,
    resCount: number,
    searchTerm: string,
    rows: Entity[],
    loading: boolean,
}

export default (class EntityBrowser<Entity extends IHasId> extends React.Component <IProps, IState<Entity>>{
        constructor(props:IProps) {
            super(props);
            const entity = ENTITIES[this.props.entity];
            const currPage = this.props.currPage || 0;
            const searchTerm = this.props.searchTerm || "";
            const query = this.props.query ? this.props.query : {table: Tables[entity.table], conditions:[]};
            this.state = {
                query,
                currPage,
                searchTerm,
                pageCount: 0,
                resCount: 0,
                rows: [],
                loading: false,
            };
        }

        async componentDidMount() {
            const entity = ENTITIES[this.props.entity];
            const currPage = this.props.currPage || 0;
            const searchTerm = this.props.searchTerm || "";
            const query = this.props.query ? this.props.query : {table: Tables[entity.table], conditions:[]};
            this.setState({
                query: query,
                currPage,
                searchTerm,
                rows: [],
            }, async ()=>{
                await this.updateQuery();
            });
        }

        onQueryChange = (query:IDBQuery) =>{
            this.setState({query, currPage: 0}, async ()=>{
                if(this.props.onQueryChange){await this.props.onQueryChange(query)}
            });
        };


        switchPage = async(p:number)=>{
            this.setState({currPage: p}, async()=>{
                await this.execQuery();
                if(this.props.onPageChange){await this.props.onPageChange(p)}
            })
        };

        setSearchTerm = async(s:string)=>{
            this.setState({searchTerm: s, currPage: 0}, async()=>{
                await this.updateQuery();
                if(this.props.onSearchTermChange){this.props.onSearchTermChange(s)}
            })
        };

        updateQuery = async()=>{
            return await Promise.all([this.execQuery(), this.getPageCount()]);
        }

        execQuery = async()=>{
            const entity = ENTITIES[this.props.entity];
            this.setState({loading: true});
            return await server.post("./"+entity.endpoint+"/query/",{
                quickSearch: this.state.searchTerm,
                query: this.state.query,
                pageNumber: this.state.currPage
            }).then((rows:Entity[])=>{
                this.setState({rows, loading:false})
            });
        };

        onRowClick = async(id: number) => {
            if(this.props.onRowClick){
                await this.props.onRowClick(id);
            }
        };

        exportQueryResult = async()=>{
            const entity = ENTITIES[this.props.entity];
            this.setState({loading: true});
            return await server.post("./"+entity.endpoint+"/export/",{
                quickSearch: this.state.searchTerm,
                query: this.state.query
            }).then((data:{csv:string}) => {
                this.setState({loading:false})
                const blob = new Blob(["\uFEFF" + data.csv], {type: "text/csv;charset=utf-8,%EF%BB%BF"});
                FileSaver.saveAs(blob, ""+entity.endpoint+"_export_"+(new Date().toISOString().split(".")[0])+".csv");
            });
        };

        getPageCount = async () => {
            const entity = ENTITIES[this.props.entity];
            return await server.post("./"+entity.endpoint+"/query_pages/",{
                quickSearch: this.state.searchTerm,
                query: this.state.query,
            }).then((res:{resultCount: string, pageCount: string})=>{
                this.setState({pageCount: parseInt(res.pageCount), resCount: parseInt(res.resultCount)})
            });
        }

        render() {
            const entity = ENTITIES[this.props.entity];
            const resCount = this.state.resCount;
            const selected = this.props.selected ? this.props.selected.slice() : [];
            const rowData = this.state.rows ? this.state.rows : []
            const rows = rowData.slice().map(a=>{
                return {
                    id: a.id,
                    data: a,
                    errors: {},
                    selected: selected.includes(a.id)
                };
            });
            return (
                <div dir="rtl">
                    {
                        !this.props.hideQueryEditor ? <span><h3>בניית שאילתה</h3>{
                            <QueryBuilder
                                table={entity.table}
                                onChange={(query:IDBQuery)=>{this.onQueryChange(query)}}
                                onSubmit={async ()=>{await this.updateQuery()}}
                                query={this.state.query}
                                enableJoins={true}
                                nested={false}
                            />
                        }
                        </span> : null
                    }
                    <div className={"table-browser-wrap"}>
					<span className={"table-actions-wrap"}>
                        <span className={"table-actions-title-section"}>
                        {
                            this.props.menuTitle ?
                                <span className={"entity-browser-title"}>{this.props.menuTitle}</span>
                                : null
                        }
                        {
                            this.props.linkToPage ?
                                <a
                                    href={"../"+entity.browsingPage.suffix+"?query="+encodeURIComponent(rison.encode(this.state.query))}
                                    style={{margin:"0 1em", fontSize:"0.6em"}}
                                    title={"לצפייה בתוצאות בעמוד השאילתה"}
                                >
                                    <FontAwesomeIcon icon={faArrowUpRightFromSquare}/>
                                </a>
                                : null
                        }
                        </span>
						{
                            !this.props.hideFreeSearch ?
                                <SearchBar
                                    onSearch={async (s:string)=>{await this.setSearchTerm(s)}}
                                    initVal={this.state.searchTerm} placeholder={"חיפוש"} waitForPost={true} width={10}
                                />: null
                        }
                        {
                            !this.props.hideQueryEditor ?
                                <button
                                    type={"button"}
                                    className={"refresh-button"}
                                    onClick={async ()=>{await this.updateQuery()}}
                                    title={"רענון שאילתה"}
                                >
                                    <FontAwesomeIcon icon={faRefresh}/>
                                </button>
                                : null
                        }
                        {
                            !this.props.hideResultCounter ?
                                <span className={"browser-res-count"}>
                                    {this.state.resCount}  תוצאות
                                </span>
                                : null
                        }
                        {
                            !this.props.hideExport && resCount ?
                                <button type={"button"} className={"table-browser-action"} onClick={()=>{return this.exportQueryResult()}}>
                                    ייצוא נתונים
                                </button> : null
                        }
                        {
                            entity.browserActions && resCount ?
                                entity.browserActions.map((a, i)=>{
                                    return <React.Fragment key={"action_"+i}>
                                        {a(this.state.query, this.state.searchTerm, this.state.currPage)}
                                    </React.Fragment>
                                })
                                : null
                        }
                    </span>
                        {(this.state.resCount || this.state.loading) ? <hr/> : null}
                        <LoadSpinner visibility={this.state.loading} radius={3}/>
                        {
                            (this.state.resCount || this.state.loading) ?
                                <ClickableTable 
                                    columns={entity.tableColumns} 
                                    data={rows} 
                                    onClick={(id:number)=>{return this.onRowClick(id)}}
                                />
                                : <span className={"no-results-wrap"}>אין תוצאות</span>
                        }
                        {
                            this.state.pageCount > 1 ?
                                <PageNav currPage={this.state.currPage}
                                         pageCount={this.state.pageCount}
                                         goToPage={async (p) => {
                                             await this.switchPage(p)
                                         }}/> :
                                null
                        }
                    </div>
                </div>
            )
        }
    }
)