import React from 'react'
import {IDBQuery} from "../../UIComponents/QueryBuilder/QueryBuilder";
import server from "../../services/server";
import {IContact} from "../../services/dataManagement/entities/contact";
import LoadSpinner from "../../UIComponents/LoadSpinner/LoadSpinner";
import Popup from "../../UIComponents/Popup/Popup";
import MailchimpListSelector from "../../UIComponents/MailchimpListSelector/MailchimpListSelector";

interface IProps {
    searchTerm: string,
    query: IDBQuery,
}
interface IState {
    addMailchimpTagPopup: boolean,
    mailchimpTag: string,
    mailchimpTagInList?: string,
    awaitingMailchimpTagAttachment?: boolean
}

export default (class QueryToMailchimp extends React.Component <IProps, IState>{
    constructor(props:IProps) {
        super((props));
        this.state = {
            awaitingMailchimpTagAttachment: false,
            addMailchimpTagPopup: false,
            mailchimpTag: "",
        };
    }

    render() {
        return <span style={{display: "flex"}}>
            <button
                className={"table-browser-action"}
                type={"button"}
                onClick={()=>{this.setState({addMailchimpTagPopup: true})}}
            >
                הוספת תג במיילצ'ימפ
            </button>   <Popup
                height={"150px"}
                visibility={this.state.addMailchimpTagPopup}
                toggleVisibility={()=>{this.setState({
                    addMailchimpTagPopup: false,
                    mailchimpTag: ""
                })}}
            >
                <div style={{"display": "flex", "flexDirection": "column", "gap": "1em"}}>
                    <div>
                        <label>רשימה</label>
                        <MailchimpListSelector onChange={(listId:string)=>{
                            this.setState({mailchimpTagInList: listId})
                        }} value={this.state.mailchimpTagInList}/></div>
                    <div>
                        <label>תג</label>
                        <div>
                            <input
                                type={"text"}
                                value={this.state.mailchimpTag}
                                onChange={(e)=>{this.setState({mailchimpTag: e.target.value})}}
                            />
                        </div>
                    </div>
                    <div>
                        {this.state.awaitingMailchimpTagAttachment ?
                            <LoadSpinner visibility={true} radius={2}/> :
                            <button type={"button"}
                                    className={"small-purple-button"}
                                    onClick={()=>{
                                        this.attachTagToQueryResults(this.props.query, this.props.searchTerm);
                                    }}>
                                צירוף תג
                            </button>
                        }
                    </div>
                </div>
            </Popup>
        </span>;
    }

    attachTagToQueryResults = (query: IDBQuery, searchTerm: string) => {
        if(this.state.awaitingMailchimpTagAttachment){
            return
        }
        const tag = this.state.mailchimpTag;
        const list = this.state.mailchimpTagInList;
        if(!tag || !list || !query){
            return;
        }
        this.setState({awaitingMailchimpTagAttachment: true}, async ()=>{
            server.post("/mailchimp/addTagToQuery", {
                query, list, tag
            }).then(()=>{
                this.setState({
                    awaitingMailchimpTagAttachment: false,
                    addMailchimpTagPopup: false,
                    mailchimpTag: ""
                }, ()=>{
                    window.alert("התג יתווסף לכל אנשי הקשר בשניות הקרובות");
                })
            });

        });
    };
})