import React from "react";
import {cn} from "../../../services/utils";
import './SmartGrid.scss';

interface IColSizes {
    sizes: { [key: string] : number },
    activeStretcher: number | null,
    activeStretcherInitPos: number | null,
    activeStretcherInitWidth: number | null
}

export interface IRow{
    classNames?: string,
    onClick?: ()=>any,
    cells: ICell[],
    actions?: JSX.Element[]
}

export interface ICol{
    key: string,
    header: JSX.Element,
    defaultWidth?: number
}

export interface ICell{
    classNames?: string,
    content: JSX.Element,
    value: string,
}

interface IProps{
    classNames?: string,
    rows: IRow[],
    cols: ICol[],
    onPaste?: (row:number, data:{[field:string]: any}[])=>void,
    actionsCount?: number
}

interface IState {
    classNames?: string,
    colSizes: IColSizes,
}

export default class SmartGrid extends React.Component <IProps, IState>{
    constructor(props: IProps) {
        super(props);
        this.state = {
            classNames: props.classNames,
            colSizes: {
                sizes: Object.fromEntries([]),
                activeStretcher: null,
                activeStretcherInitPos: null,
                activeStretcherInitWidth: null
            }
        };
    }

    static getDerivedStateFromProps(nextProps: IProps, state: IState) {
        const colSizes = state.colSizes;
        const cols = nextProps.cols;
        cols.forEach((c)=>{
            if(!colSizes.sizes[c.key]){
                colSizes.sizes[c.key] = c.defaultWidth ? c.defaultWidth * 16 : 10;
            }
        });

        return {
            classNames: nextProps.classNames,
            cols: nextProps.cols,
            rows: nextProps.rows,
            colSizes: colSizes,
        };
    }

    onMouseMove = (e:MouseEvent) => {
        const colSizes = this.state.colSizes;
        const cols = this.props.cols.slice();
        if(
            colSizes.activeStretcherInitPos !== null &&
            colSizes.activeStretcher !== null &&
            colSizes.activeStretcherInitWidth !== null
        ){
            colSizes.sizes[cols[colSizes.activeStretcher].key] =
                colSizes.activeStretcherInitWidth + colSizes.activeStretcherInitPos - e.clientX;
            this.setState({colSizes});
        }
    };

    onMouseUp = ()=> {
        const colSizes = this.state.colSizes;
        colSizes.activeStretcher = null;
        colSizes.activeStretcherInitPos = null;
        colSizes.activeStretcherInitWidth = null;
        this.setState({colSizes});
    };

    componentDidMount(): void {
        window.addEventListener('mousemove', this.onMouseMove, false);
        window.addEventListener('mouseup', this.onMouseUp, false);
    }
    componentWillUnmount(): void {
        window.removeEventListener('mousemove', this.onMouseMove, false);
        window.removeEventListener('mouseup', this.onMouseUp, false);
    }

    render(){
        const cols = this.props.cols.slice();
        const rows = this.props.rows.slice();
        const colSizes = this.state.colSizes;
        return (
            <div className={"selectable-table " + (this.state.classNames || "")}
            >
                <div className={"selectable-table-header"}>
                    <div className={"selectable-table-row"}>
                        <div className={"selectable-table-row-actions"}
                             style={{width: (this.props.actionsCount ? this.props.actionsCount :0) + "em"}}>
                        </div>
                        {cols.map(((c, j)=>{
                            return <React.Fragment key={"col_"+j}>
                                <div className={"selectable-table-cell " +
                                cn({
                                    "grid-top": true,
                                    "grid-bottom": 0 === rows.length,
                                    "grid-left": j === (cols.length -1),
                                    "grid-right": j === 0
                                })
                                }
                                     style={{
                                         flexGrow: 1,
                                         flexBasis: colSizes.sizes[c.key] + "px",
                                         width: colSizes.sizes[c.key] + "px",
                                     }}>
                                    {c.header}
                                </div>
                                {
                                    <div className={"column-stretcher-wrap"}>
                                        <div className={"column-stretcher"}
                                             onMouseDown={(e)=>{
                                                 const colSizes = this.state.colSizes;
                                                 colSizes.activeStretcher = j;
                                                 colSizes.activeStretcherInitPos = (e.target as HTMLDivElement).getBoundingClientRect().left;
                                                 colSizes.activeStretcherInitWidth = colSizes.sizes[c.key];
                                                 this.setState({colSizes});
                                             }}
                                        />
                                    </div>
                                }
                            </React.Fragment>;
                        }))}
                    </div>
                </div>
                <div className="contacts-table-body selectable-table-body">
                    {
                        rows.map((r, i)=>{
                            const additionalRowClasses = r.classNames ? r.classNames : "";
                            return <div
                                onClick={()=>{return r.onClick ? r.onClick() : null}}
                                key={"row_"+i}
                                className={"selectable-table-row " + additionalRowClasses}>
                                <div className={"selectable-table-row-actions"}
                                     style={{width: (this.props.actionsCount ? this.props.actionsCount :0) + "em"}}
                                >
                                    {r.actions ? r.actions.map(a=>{
                                        return a;
                                    }):null}
                                </div>
                                {cols.map(((c, j)=>{
                                    const additionalCellClasses = r.cells[j].classNames ? r.cells[j].classNames : "";
                                    return <div key={"cell_"+i+"_"+j} className={additionalCellClasses + " " + cn({
                                        "selectable-table-cell": true,
                                        "grid-top": false,
                                        "grid-bottom": i === (rows.length - 1),
                                        "grid-left": j === (cols.length -1),
                                        "grid-right": j === 0
                                    })
                                    }
                                                style={{
                                                    flexGrow: 1,
                                                    flexBasis: colSizes.sizes[c.key] + "px",
                                                    width: colSizes.sizes[c.key] + "px",
                                                }}
                                    >
                                        {r.cells[j].content}
                                    </div>;
                                }))}
                            </div>
                        })
                    }
                </div>
            </div>
        );
    }
}