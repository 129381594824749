import {
    useLocation,
    useNavigate,
    useParams, useSearchParams,
} from "react-router-dom";
import React from "react";

export default function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        let [searchParams, setSearchParams] = useSearchParams();
        return (
            <Component
                {...props}
                location = {location}
                navigate = {navigate}
                params = {params}
                searchParams = {searchParams}
                setSearchParams = {setSearchParams}
            />
        );
    }

    return ComponentWithRouterProp;
}