import React from 'react';
import "./SearchBar.scss";
import { library } from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faSearch} from '@fortawesome/free-solid-svg-icons';
library.add(faSearch);

interface IProps{
    onSearch: (term:string)=>void,
    initVal?: string,
    placeholder?: string,
    waitForPost?: boolean,
    width?: number
}

interface IState{
    onSearch: (term:string)=>void,
    search: string,
    placeholder: string,
    waitForPost: boolean
}

export default class SearchBar extends React.Component<IProps, IState> {
    constructor(props:IProps) {
        super(props);
        this.state = {
            onSearch: props.onSearch,
            search: props.initVal ? props.initVal : "",
            placeholder: props.placeholder ? props.placeholder : "",
            waitForPost: props.waitForPost ? props.waitForPost : false
        };
    }

    handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({search: e.target.value}, ()=>{
            if(!this.state.waitForPost){
                this.handlePost();
            }
        });
    };

    handlePost=() => {
        this.state.onSearch(this.state.search);
    };

    handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if(e.key === 'Enter'){
            this.handlePost();
        }
    };

    render() {
        return(
            <div className="search-bar-wrap">
                <input
                    style={{width: this.props.width ? "" + this.props.width + "em" : "100%"}}
                    value={this.state.search}
                    className={"search-input"}
                    onChange={(e)=>this.handleInputChange(e)}
                    onKeyPress={(e)=>{this.handleKeyPress(e)}}
                    placeholder={this.state.placeholder}/>
                <button type={"button"} title={"חיפוש"} onClick={()=>{this.handlePost()}} className='search-button'>
                    <FontAwesomeIcon className='search-button-icon' icon={faSearch}/>
                </button>
            </div>
        );
    }
}
