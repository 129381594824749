import React from 'react'
import './ClickableTable.scss';
import {cn} from "../../../services/utils";
import {IEntityTableCol, TABLE_COLS_INPUTS} from "../../../services/dataManagement/tableColumns";
import SmartGrid from "../SmartGrid/SmartGrid";
import {IHasId} from "../../../services/interfaces";
import {FIELD_CONTROLS, FIELD_JUSTIFICATION} from "../../../services/dataManagement/fieldControls";

interface IProps {
    columns: IEntityTableCol[],
    data: IClickableTableRow[],
    onClick: Function
}

export interface IClickableTableRow extends IHasId{
    data: any,
    errors: {[key:string]: string},
    selected?: boolean
}

interface IState {}

export default class ClickableTable extends React.Component <IProps, IState> {
    constructor(props:IProps, ) {
        super(props);
        this.state = {};
    }

    render() {
        const columns = this.props.columns.slice();
        const cols = columns.map(c=>{
            return {
                key: c.field.field,
                header: <span>{c.field.title}</span>,
                defaultWidth: c.defaultWidth
            }
        });
        const rows = this.props.data.slice().map(r=>{
            return {
                classNames: cn({"selected-row": !!r.selected}),
                cells: columns.map(c=>{
                    const val = r.data[c.field.field];
                    return {
                        classNames: "",
                        content: FIELD_CONTROLS[TABLE_COLS_INPUTS[c.field.type]]({
                            readonly: !!c.readonly,
                            disabled: !!c.field,
                            dir: c.field.dir || FIELD_JUSTIFICATION.AUTO,
                            value: val,
                            options: c.field.options ? c.field.options : {},
                            onChange: ()=> {}
                        }),
                        value: val,
                    }
                }),
                onClick:()=>{this.props.onClick(r.id)}
            }
        });
        return (
            <div className={"table-lister"}>
                <SmartGrid cols={cols} rows={rows}/>
            </div>
        )
    }
}

