import React from 'react';
import {Tables, DBTablesDict, IDBColumn, IDBTableRelation} from "../../services/dataManagement/tables";
import {ColumnTypeDict} from "./DBColumnTypes";
import './QueryCondition.scss';
import QueryBuilder, {IDBQuery} from "./QueryBuilder";

export const JOIN_CONDITION_PREFIX = "j";
export const COLUMN_CONDITION_PREFIX = "c";

interface IProps {
    table: string,
    subject: string | null,
    filter: string | null,
    value: any,
    onFilterChange: (subject:string | null, filter: string | null, value:any)=>void,
    onSubmit: ()=>void
}

interface IState {
    table: string,
    subject: string | null,
    filter: string | null,
    value: any,
    onFilterChange: (subject:string | null, filter: string | null, value:any)=>void,
    subjectDict: {[key:string]: IConditionSubject},
    onSubmit: ()=>void
}

enum conditionSubjectTypes {
    COLUMN, JOIN
}

interface IConditionSubject {
    type: conditionSubjectTypes,
    key: string,
    label: string
}

interface IColumnCondition extends IConditionSubject{
    column: IDBColumn
}

interface IJoinCondition extends IConditionSubject{
    join: IDBTableRelation
}

export default class QueryCondition extends React.Component <IProps, IState> {
    constructor(props: IProps) {
        super(props);
        const subjectDict:{[key:string]: IConditionSubject} = {};
        if(!this.props.table){
            return;
        }
        const table = DBTablesDict[Tables[props.table as keyof typeof Tables]];
        if(!table){
            return;
        }
        Object.values(table.columns).forEach(c=>{
            const newColumnCondition: IColumnCondition = {
                type: conditionSubjectTypes.COLUMN,
                key: COLUMN_CONDITION_PREFIX + "_" + c.key,
                label: c.label,
                column: c,
            };
            subjectDict[COLUMN_CONDITION_PREFIX + "_" + c.key] = newColumnCondition;
        });
        Object.values(table.availableJoins).forEach(j=>{
            const newJoinCondition: IJoinCondition = {
                type: conditionSubjectTypes.JOIN,
                key: JOIN_CONDITION_PREFIX + "_" + j.id,
                label: j.title,
                join: j
            };
            subjectDict[JOIN_CONDITION_PREFIX + "_" + j.id] = newJoinCondition;
        });
        this.state = {
            table: props.table,
            subject: props.subject,
            filter: props.filter,
            value: props.value,
            onFilterChange: props.onFilterChange,
            onSubmit: props.onSubmit,
            subjectDict: subjectDict
        };
    }

    async componentDidMount() {
        const subjectDict = this.state.subjectDict;
        const availableSubjects = Object.values(subjectDict).slice();
        if(availableSubjects.length === 1 && !(this.state.subject && this.state.subject.length)){
            await this.selectSubjectToFilter(availableSubjects[0].key)
        }
    }

    static getDerivedStateFromProps(nextProps: IProps, state: IState) {
        return {
            subject: nextProps.subject,
            filter: nextProps.filter,
            value: nextProps.value,
            onFilterChange: nextProps.onFilterChange,
            onSubmit: nextProps.onSubmit,
        };
    }

    selectSubjectToFilter = async (key:string) => {
        const subjectDict = this.state.subjectDict;
        let filter: string | null = null;
        if(subjectDict[key].type === conditionSubjectTypes.COLUMN){
            const c = (subjectDict[key] as IColumnCondition);
            const colType = ColumnTypeDict[c.column.type];
            if(colType && Object.values(colType.availableFilters).length === 1){
                filter = Object.values(colType.availableFilters)[0].key;
            }
        }
        this.setState({value: null, subject: key, filter: null}, async ()=>{
            await this.state.onFilterChange(key, null, null);
            if(!!filter){
                this.selectFilter(filter)
            }
        });
    };

    selectFilter = (filterKey:string) => {
        this.state.onFilterChange(this.state.subject, filterKey, null);
    };

    onFilterValueChange = (value:any)=>{
        this.state.onFilterChange(this.state.subject, this.state.filter, value);
    };

    onSubmit = () => {
        this.state.onSubmit();
    };

    onJoinConditionEdit = (joinId: string, v: IDBQuery) => {
        const value = {join: joinId, innerQuery: v};
        this.state.onFilterChange(this.state.subject, this.state.filter, value);
    };

    render() {
        const table = DBTablesDict[Tables[this.state.table as keyof typeof Tables]];
        if(!table){
            return null;
        }
        const subjectDict = this.state.subjectDict;
        const subjects = Object.values(subjectDict).slice();
        const subject = this.state.subject;
        const filter = this.state.filter;

        const isColumnCondition = subject && subjectDict[subject].type === conditionSubjectTypes.COLUMN;
        const isJoinCondition = subject && subjectDict[subject].type === conditionSubjectTypes.JOIN;
        const colParams = isColumnCondition ? (subjectDict[subject] as IColumnCondition).column : null;
        const colType = colParams ? ColumnTypeDict[colParams.type] : null;
        const joinType = isJoinCondition ? (subjectDict[subject] as IJoinCondition).join : null;

        const filterParams = colType && filter ? colType.availableFilters[filter] : null;
        return <div className={"condition-builder"} onClick={(e: React.MouseEvent<HTMLDivElement>)=>{e.stopPropagation()}}>
            <div>
                <select
                    className={"condition-column-selector"}
                    onChange={async (e)=>{await this.selectSubjectToFilter(e.target.value)}}
                    value={this.state.subject ? this.state.subject : ""}>
                    <option value={""}/>
                    {
                        subjects.map((s, i)=>{
                            return <option key={"column-selector-"+s.key} value={s.key}>
                                {s.label}
                            </option>
                        })
                    }
                </select>
            </div>
            <div>
               {
                   colType ?
                        (Object.values(colType.availableFilters).length > 1?
                       <select
                           className={"condition-filter-selector"}
                           onChange={(e)=>{this.selectFilter(e.target.value)}}
                           value={this.state.filter ? this.state.filter : ""}>
                           <option value={""}/>
                           {
                               Object.values(colType.availableFilters).map((f, i)=>{
                                   return <option key={"filter-selector-"+f.key} value={f.key}>{f.label}</option>
                               })
                           }
                       </select> 
                       :
                       <div className='filter-one-option'>{Object.values(colType.availableFilters)[0].label}</div>)
                       : null
               }
                {
                    joinType ? <QueryBuilder
                        table={joinType.targetTable}
                        onChange={(query:any)=>{this.onJoinConditionEdit(joinType.id, query)}}
                        onSubmit={()=>{this.onSubmit()}}
                        query={this.state.value ? this.state.value.innerQuery : null}
                        enableJoins={true}
                        nested={true}
                    /> : null
                }
            </div>
            <div>
                {
                    filterParams && colParams ?
                        filterParams.inputTemplate(this.onFilterValueChange, this.onSubmit, this.state.value, colParams.options)
                        : null
                }
            </div>
        </div>
    }
}
