import {IEntity} from "../entities";
import {Tables} from "../tables";
import {fieldType, IField} from "../fields";
import {IEntityTableCol} from "../tableColumns";
import {ICard} from "../cardControls";

export enum EUserFields {
    id, create_date,update_date, email, locked, organizer_access, typer_access, role
}

export const userFields:{[key in EUserFields]: IField} = {
    [EUserFields.id]: {
        field: "id",
        defaultVal: null,
        type: fieldType.textType,
        title: "id"
    },
    [EUserFields.create_date]: {
        field: "create_date",
        defaultVal: () => { return (new Date()).toISOString() },
        type: fieldType.dateType,
        title: "תאריך יצירה"
    },
    [EUserFields.update_date]: {
        field: "update_date",
        defaultVal: () => { return (new Date()).toISOString() },
        type: fieldType.dateType,
        title: "תאריך עדכון"
    },
    [EUserFields.email]: {
        field: "email",
        defaultVal: "",
        type: fieldType.textType,
        title: "אימייל"
    },
    [EUserFields.locked]: {
        field: "locked",
        defaultVal: false,
        type: fieldType.booleanType,
        title: "נעילה"
    },
    [EUserFields.organizer_access]: {
        field: "organizer_access",
        defaultVal: false,
        type: fieldType.booleanType,
        title: "גישת ניהול"
    },
    [EUserFields.typer_access]: {
        field: "typer_access",
        defaultVal: false,
        type: fieldType.booleanType,
        title: "גישת קלדנות"
    },
    [EUserFields.role]: {
        field: "role",
        defaultVal: false,
        type: fieldType.dropdown,
        title: "תפקיד",
        options: [
            {
                value: "admin",
                text: "אדמין"
            },
            {
                value: "organizer",
                text: "אורגנייזר"
            },
            {
                value: "user",
                text: "פעיל"
            },
        ]
    },
};

export const UserBrowserCols:IEntityTableCol[] = [
    {
        field: userFields[EUserFields.create_date],
        defaultWidth: 5,
        readonly: true
    },
    {
        field: userFields[EUserFields.email],
        defaultWidth: 15,
        readonly: true
    },
    {
        field: userFields[EUserFields.organizer_access],
        defaultWidth: 5,
        readonly: true
    },
    {
        field: userFields[EUserFields.locked],
        defaultWidth: 5,
        readonly: true
    },
    {
        field: userFields[EUserFields.role],
        defaultWidth: 5,
        readonly: true,
    },
]

export const UserCard:ICard = {
    sections: [
        {
            compact: true,
            hideOnNew: true,
            fields: [
                {
                    field: userFields[EUserFields.id],
                    readonly: true
                },
                {
                    field: userFields[EUserFields.create_date],
                    readonly: true
                },
                {
                    field: userFields[EUserFields.update_date],
                    readonly: true
                }
            ]
        },
        {
            fields: [
                {field: userFields[EUserFields.email]},
                {field: userFields[EUserFields.locked]},
            ]
        },
        {
            fields: [
                {field: userFields[EUserFields.role]},
            ]
        },
        {
            fields: [
                {field: userFields[EUserFields.organizer_access]},
                {field: userFields[EUserFields.typer_access]},
            ]
        },
    ],
    relatedEntities: []
}

export const UserEntity:IEntity = {
    browsingPage: {
        suffix: "users",
        title: "משתמשים",
        newItemLabel: "משתמש חדש"
    },
    cardPage: {
        suffix: "user",
        title: "משתמש",
    },
    endpoint: "users",
    fields: Object.values(userFields),
    table: Tables.DB_TABLE_USER,
    tableColumns: UserBrowserCols,
    cardLayout: UserCard
}