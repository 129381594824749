import {EDBColumnTypes} from "../../UIComponents/QueryBuilder/DBColumnTypes";

export interface IDBColumn {
    type: EDBColumnTypes,
    label: string,
    key: string,
    options?: any
}

export type IDBTable = {
    columns: {[key: string]: IDBColumn},
    id: string,
    title: string,
    availableJoins: {[key: string]: IDBTableRelation}
}

export enum Tables {
    DB_TABLE_ACTIVITY,
    DB_TABLE_CITY,
    DB_TABLE_CONTACT,
    DB_TABLE_ACTIVITY_TAG,
    DB_TABLE_CONTACT_GROUP,
    DB_TABLE_ACTIVITY_TYPE,
    DB_TABLE_USER,
    DB_TABLE_DATASOURCE,
    DB_TABLE_ACTIVITY_CUSTOM_FIELDS,
    DB_TABLE_CONTACT_CUSTOM_FIELDS
}

export type IDBTableDict = { [key in Tables]: IDBTable }

export interface IDBTableRelation {
    id: string,
    title: string,
    targetTable: Tables,
    joinOnMatch: {
        from: string,
        op: string,
        to: string,
        tableToJoin: string,
        autoTrigger: boolean
    }[],
}


export const DBTablesDict: IDBTableDict = {
    [Tables.DB_TABLE_ACTIVITY]: {
        id: "activity",
        title: "פעילות",
        columns: {
            id: {key: "id", label: "בחירת פעילות", type: EDBColumnTypes.ACTIVITY_COL},
            title: {key: "title", label: "כותרת", type: EDBColumnTypes.STRING_COL},
            // TODO: custom_fields: {key: "custom_fields", label: "שדות נוספים", type: EDBColumnTypes.JSON_COL},
            create_date: {key: "create_date", label: "תאריך יצירת רשומה", type: EDBColumnTypes.DATE_COL},
            activity_type_id: {key: "activity_type_id", label: "סוג פעילות", type: EDBColumnTypes.ACTIVITY_TYPE_COL},
        },
        availableJoins: {
            has_tags: {
                id: "has_tags",
                title: "עם התגים",
                targetTable: Tables.DB_TABLE_ACTIVITY_TAG,
                joinOnMatch: [
                    {
                        from: "id", op: "=", to: "activity_id", tableToJoin: "activity_activity_tags", autoTrigger: true
                    },
                    {
                        from: "tag_id", op: "=", to: "id", tableToJoin: "activity_tag", autoTrigger: false,
                    },
                ],
            },
            participants:{
                id: "participants",
                title: "השתתפות בפעילות",
                targetTable: Tables.DB_TABLE_CONTACT,
                joinOnMatch: [
                    {
                        from: "id",
                        op: "=",
                        to: "activity_id",
                        tableToJoin: "data_source",
                        autoTrigger: false
                    },
                    {
                        from: "id",
                        op: "=",
                        to: "data_source_id",
                        tableToJoin: "data_source_contacts",
                        autoTrigger: true
                    },
                    {
                        from: "contact_id",
                        op: "=",
                        to: "id",
                        tableToJoin: "contact",
                        autoTrigger: false,
                    }
                ]
            },
        }
    },
    [Tables.DB_TABLE_CONTACT]: {
        id: "contact",
        title: "איש קשר",
        columns: {
            id: {key: "id", label: "בחירת איש קשר", type: EDBColumnTypes.CONTACT_COL},
            first_name:{key: "first_name", label: "שם פרטי", type: EDBColumnTypes.STRING_COL},
            last_name:{key: "last_name", label: "שם משפחה", type: EDBColumnTypes.STRING_COL},
            phone_number:{key: "phone_number", label: "מספר טלפון", type: EDBColumnTypes.STRING_COL},
            email_address:{key: "email_address", label: "כתובת אימייל", type: EDBColumnTypes.STRING_COL},
            membership_status:{key: "membership_status", label: "סטטוס חברות", type: EDBColumnTypes.MEMBERSHIP_STATUS_COL},
            custom_fields:{key: "custom_fields", label: "שדות נוספים", type: EDBColumnTypes.CONTACT_CUSTOM_FIELDS_VAL_COL,},
            create_date:{key: "create_date", label: "תאריך יצירת רשומה", type: EDBColumnTypes.DATE_COL},
            update_date:{key: "update_date", label: "תאריך עדכון אחרון", type: EDBColumnTypes.DATE_COL},
        },
        availableJoins: {
            residence:{
                id: "residence",
                targetTable: Tables.DB_TABLE_CITY,
                title: "עיר מגורים",
                joinOnMatch: [
                    {
                        from: "city_id", op: "=", to: "id", tableToJoin: "city", autoTrigger: false,
                    },
                ],
            },
            participation_in_activities:{
                id: "participation_in_activities",
                title: "השתתפות בפעילות",
                targetTable: Tables.DB_TABLE_ACTIVITY,
                joinOnMatch: [
                    {
                        from: "id",
                        op: "=",
                        to: "contact_id",
                        tableToJoin: "data_source_contacts",
                        autoTrigger: false
                    },
                    {
                        from: "data_source_id",
                        op: "=",
                        to: "id",
                        tableToJoin: "data_source",
                        autoTrigger: true
                    },
                    {
                        from: "activity_id",
                        op: "=",
                        to: "id",
                        tableToJoin: "activity",
                        autoTrigger: false,
                    }
                ]
            },
            inclusion_in_datasource:{
                id: "inclusion_in_datasource",
                title: "הופעה במקור מידע",
                targetTable: Tables.DB_TABLE_DATASOURCE,
                joinOnMatch: [
                    {
                        from: "id",
                        op: "=",
                        to: "contact_id",
                        tableToJoin: "data_source_contacts",
                        autoTrigger: false
                    },
                    {
                        from: "data_source_id",
                        op: "=",
                        to: "id",
                        tableToJoin: "data_source",
                        autoTrigger: true
                    }
                ]
            },
            membership_in_contact_group:{
                id: "membership_in_contact_group",
                title: "קבוצה שמורה",
                targetTable: Tables.DB_TABLE_CONTACT_GROUP,
                joinOnMatch: [
                    {
                        from: "id",
                        op: "=",
                        to: "contact_id",
                        tableToJoin: "contact_group_contacts",
                        autoTrigger: true,
                    },
                    {
                        from: "group_id",
                        op: "=",
                        to: "id",
                        tableToJoin: "contact_group",
                        autoTrigger: false,
                    },
                ]
            },
        }
    },
    [Tables.DB_TABLE_CITY]: {
        id: "city",
        title: "עיר",
        columns: {
            id:{key: "id", label: "בחירת ערים", type: EDBColumnTypes.CITY_COL},
            name:{key: "name", label: "שם קנוני", type: EDBColumnTypes.STRING_COL},
            coordinates:{key: "coordinates", label: "קואורדינטות", type: EDBColumnTypes.POINT_COL},
        },
        availableJoins: {}
    },
    [Tables.DB_TABLE_ACTIVITY_TAG]: {
        id: "activity_tag",
        title: "תגי פעילות",
        columns: {
            id: {key: "id", label: "בחירת תג", type: EDBColumnTypes.ACTIVITY_TAG_COL},
        },
        availableJoins: {}
    },
    [Tables.DB_TABLE_CONTACT_GROUP]: {
        id: "contact_group",
        title: "קבוצת אנשי קשר שמורה",
        columns: {
            id: {key: "id", label: "בחירת קבוצה", type: EDBColumnTypes.CONTACT_GROUP_COL},
            title: {key: "title", label: "שם קבוצה", type: EDBColumnTypes.STRING_COL},
        },
        availableJoins: {
            includes_member:{
                id: "includes_member",
                title: "מכילה את איש הקשר",
                targetTable: Tables.DB_TABLE_CONTACT,
                joinOnMatch: [
                    {
                        from: "id",
                        op: "=",
                        to: "group_id",
                        tableToJoin: "contact_group_contacts",
                        autoTrigger: true,
                    },
                    {
                        from: "contact_id",
                        op: "=",
                        to: "id",
                        tableToJoin: "contact",
                        autoTrigger: false,
                    },
                ]
            }
        }
    },
    [Tables.DB_TABLE_DATASOURCE]: {
        id: "data_source",
        title: "מקורות מידע",
        columns: {
            id: {key: "id", label: "בחירת מקור מידע", type: EDBColumnTypes.DATASOURCE_COL},
            activity_id: {key: "activity_id", label: "פעילות", type: EDBColumnTypes.ACTIVITY_COL},
        },
        availableJoins: {

        }
    },
    [Tables.DB_TABLE_CONTACT_CUSTOM_FIELDS]: {
        id: "custom_contact_field",
        title: "שדות פרופיל נוספים",
        columns: {
            id: {key: "id", label: "בחירת שדה", type: EDBColumnTypes.CONTACT_CUSTOM_FIELDS_COL},
        },
        availableJoins: {}
    },
    [Tables.DB_TABLE_ACTIVITY_CUSTOM_FIELDS]: {
        id: "custom_activity_field",
        title: "שדות פעילות נוספים",
        columns: {
            id: {key: "id", label: "בחירת שדה", type: EDBColumnTypes.ACTIVITY_CUSTOM_FIELDS_COL},
        },
        availableJoins: {}
    },
    [Tables.DB_TABLE_ACTIVITY_TYPE]: {
        id: "activity_type",
        title: "סוגי פעילות",
        columns: {
            id: {key: "id", label: "בחירת סוג פעילות", type: EDBColumnTypes.ACTIVITY_TYPE_COL},
        },
        availableJoins: {}
    },
    [Tables.DB_TABLE_USER]: {
        id: "gramsci_user",
        title: "משתמשים",
        columns: {
            id: {key: "id", label: "בחירת משתמש", type: EDBColumnTypes.USER_COL},
        },
        availableJoins: {}
    },
};