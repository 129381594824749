import React from 'react';
import './TopNavBar.scss';
import server from '../../services/server';
import cookie from 'js-cookie';

import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
	faBars,
	faCalendar, faClipboardList, faContactBook,
	faFileImport,
	faFilter, faIdCard, faMapLocationDot, faPeopleGroup, faShapes, faTags,
	faUsers,
	IconDefinition
} from '@fortawesome/free-solid-svg-icons'
import withRouter from "../../services/withRouter";
import {ENTITIES} from "../../services/dataManagement/entities";
import {E_PAGE_TYPE} from "../../services/dataManagement/pages";
import {E_ENTITIES} from "../../services/dataManagement/entityDefinitions";

library.add(faBars);

interface IProps{
	location: any,
	navigate: any,
	params: any
	searchParams: any
	setSearchParams: any
	justification?: string
	children: JSX.Element
}
interface IState{
	emoji:string,
	menuOpened: boolean,
	permissions: any
}

export default withRouter(class TopNavBar extends React.Component<IProps, IState> {
	constructor(props:IProps) {
		super(props);
		this.state = {
			menuOpened: false,
			emoji: "🌹",
			permissions: {}
		};
	}
	componentDidMount() {
		this.setState({permissions: this.getPermissions()})
	}
	getPermissions(){
		const json = cookie.get("permissions");
		if(!json)
			return {};
		try{
			return JSON.parse(json);
		}
		catch (e) {
			return {}
		}
	}
	logout(){
		server.get('login/logout', {})
			.then(() => {
				this.props.navigate('/Login')
			});
	}
	toggleMenu = () => {
		if(!this.state.menuOpened){
			const possibleMenuEmojis = ["🌹", "🚩", "☭", "✊", "✌", "🕊️", "🚄🚍", "💜", "🏳️‍🌈"];
			const emoji = possibleMenuEmojis[Math.floor(Math.random()*possibleMenuEmojis.length)];
			this.setState({menuOpened: true, emoji: emoji});
		}
		else{
			this.setState({menuOpened: false});
		}
	}

	goToPage = (page:string) => {
		this.props.navigate('/' + page);
	}

	entityPageToMenuItem = (p:{e:E_ENTITIES, type: E_PAGE_TYPE, i:IconDefinition}) => {
		switch(p.type) {
			case E_PAGE_TYPE.CARD:
				return {
					title: <div>{ENTITIES[p.e].cardPage.title}</div>,
					goto: ()=> {},
					icon: p.i
				}
			case E_PAGE_TYPE.BROWSER:
				return {
					title: <div>{ENTITIES[p.e].browsingPage.title}</div>,
					goto: ()=> {this.goToPage(ENTITIES[p.e].browsingPage.suffix)},
					icon: p.i
				}
			default:
				return {
					title: <div></div>,
					goto: ()=> {},
					icon: p.i
				}
		}
	}

	menuItems:{title:JSX.Element, goto: ()=>void, icon: IconDefinition}[] = [
		this.entityPageToMenuItem({e:E_ENTITIES.contacts, type:E_PAGE_TYPE.BROWSER, i:faContactBook}),
		{
			title: <div>ייבוא אנשי קשר</div>,
			goto: ()=>{this.goToPage("import-contacts")},
			icon: faFileImport
		},
		this.entityPageToMenuItem({e:E_ENTITIES.activities, type:E_PAGE_TYPE.BROWSER, i: faCalendar}),
		this.entityPageToMenuItem({e:E_ENTITIES.dataSources, type:E_PAGE_TYPE.BROWSER, i: faFilter}),
		this.entityPageToMenuItem({e:E_ENTITIES.cities, type:E_PAGE_TYPE.BROWSER, i: faMapLocationDot}),
		this.entityPageToMenuItem({e:E_ENTITIES.contactGroups, type:E_PAGE_TYPE.BROWSER, i:faPeopleGroup}),
		this.entityPageToMenuItem({e:E_ENTITIES.activityTags, type:E_PAGE_TYPE.BROWSER, i:faTags}),
		this.entityPageToMenuItem({e:E_ENTITIES.activityTypes, type:E_PAGE_TYPE.BROWSER, i:faShapes}),
		this.entityPageToMenuItem({e:E_ENTITIES.customActivityFields, type:E_PAGE_TYPE.BROWSER, i:faClipboardList}),
		this.entityPageToMenuItem({e:E_ENTITIES.customContactFields, type:E_PAGE_TYPE.BROWSER, i:faIdCard}),
		{
			title: <div>משתמשים</div>,
			goto: ()=>{this.goToPage("users")},
			icon: faUsers
		},
	];

	render() {
		const permissions = this.state.permissions;
		return (
			<div className="nav-bar-wrapper" style={{"justifyContent":(this.props.justification || "end")}} dir={"rtl"}>
				<div className="nav-bar">
                	<div className={"nav-bar-logo-menu-wrap " + (this.state.menuOpened ? "sidebar-open" : "")}>
						<div className="nav-bar-icon" onClick={this.toggleMenu}>
							<FontAwesomeIcon icon={faBars}/>
						</div>
						<div className="sidebar-background" onClick={this.toggleMenu}/>
						<div className={"sidebar-wrap"}>
							<div className={"sidebar"}>
								<div className="sidebar-title">
									{this.state.emoji}
								</div>
								<div className="sidebar-options">
									{
										this.menuItems.map((p, i)=>{
											return <div
												key={"page_"+i}
												className="sidebar-item button"
												onClick={()=>{p.goto();}}
											>
												<FontAwesomeIcon icon={p.icon}/>
												{p.title}
											</div>
										})
									}
									<div className="sidebar-item button" onClick={this.logout.bind(this)}>
										<div>התנתקות מהמערכת</div>
									</div>
								</div>
							</div>
                		</div>
					</div>
					{this.props.children}
				</div>
			</div>
		)
	}
})
