import {IEntity} from "../entities";
import {Tables} from "../tables";
import {fieldType, IField} from "../fields";
import {IEntityTableCol} from "../tableColumns";
import {ICard} from "../cardControls";
import {E_ENTITIES} from "../entityDefinitions";
import {IColumnCondition} from "../../../UIComponents/QueryBuilder/QueryBuilder";

export enum EActivityTagFields {
    id, create_date,update_date, title, supertags,supertag_ids
}

export const activityTagFields : {[key in EActivityTagFields]: IField}= {
    [EActivityTagFields.id]:{
        field: "id",
        defaultVal: null,
        type: fieldType.textType,
        title: "id"
    },
    [EActivityTagFields.create_date]: {
        field: "create_date",
        defaultVal: () => { return (new Date()).toISOString() },
        type: fieldType.dateType,
        title: "תאריך יצירה"
    },
    [EActivityTagFields.update_date]: {
        field: "update_date",
        defaultVal: () => { return (new Date()).toISOString() },
        type: fieldType.dateType,
        title: "תאריך עדכון"
    },
    [EActivityTagFields.title]: {
        field: "title",
        defaultVal: "",
        type: fieldType.textType,
        validate: [validateTitleFormat],
        title: "תג"
    },
    [EActivityTagFields.supertag_ids]:{
        field: "supertags",
        defaultVal: [],
        options: {entity: E_ENTITIES.activityTags},
        type: fieldType.entitiesArrayType,
        title: "תת-תג של"
    },
    [EActivityTagFields.supertags]:{
        field: "supertags",
        defaultVal: [],
        type: fieldType.stringArray,
        title: "תת-תג של"
    },
};

export interface IActivityTag {
    id: number,
    title: string,
    create_date: Date,
    update_date: Date,
    subtags: number[]
}

export const ActivityTagBrowserCols:IEntityTableCol[] = [
    {
        field: activityTagFields[EActivityTagFields.title],
        readonly: true,
        defaultWidth: 5,
    },
    {
        field: activityTagFields[EActivityTagFields.supertags],
        readonly: true,
        defaultWidth: 10,
    },
]

export const ActivityTagCard:ICard = {
    sections: [
        {
            compact: true,
            hideOnNew: true,
            fields: [
                {
                    field: activityTagFields[EActivityTagFields.id],
                    readonly: true
                },
                {
                    field: activityTagFields[EActivityTagFields.create_date],
                    readonly: true
                },
                {
                    field: activityTagFields[EActivityTagFields.update_date],
                    readonly: true
                }
            ]
        },
        {
            fields: [
                {field: activityTagFields[EActivityTagFields.title]},
                {field: activityTagFields[EActivityTagFields.supertag_ids]},
            ]
        },
    ],
    relatedEntities: [
        {
            title: "פעילויות",
            entity: E_ENTITIES.activities,
            query: (id:number)=> {
                return {
                    "conditions": [
                        {
                            "filter": null,
                            "id": "",
                            "l": 0,
                            "s": false,
                            "subject": "j_has_tags",
                            "t": 1,
                            "value": {
                                "join": "has_tags",
                                "innerQuery": {
                                    "conditions": [
                                        {
                                            "filter": "at_in",
                                            "id": "",
                                            "l": 0,
                                            "s": false,
                                            "subject": "c_id",
                                            "t": 1,
                                            "value": [id]
                                        }
                                    ],
                                    "table": Tables[Tables.DB_TABLE_ACTIVITY_TAG]
                                }
                            }
                        }
                    ],
                    "table": Tables[Tables.DB_TABLE_ACTIVITY]
                }
            }
        },
    ]
}

function validateTitleFormat(title:string) {
    const valid = title && title.length;
    return {valid: !!valid, err: valid ? undefined : "שם תג לא תקין"}
}

export const ActivityTagEntity:IEntity = {
    browsingPage: {
        suffix: "activity-tags",
        title: "תגי פעילות",
        newItemLabel: "תג חדש"
    },
    cardPage: {
        suffix: "activity-tag",
        title: "תג פעילות",
    },
    endpoint: "activityCategories",
    fields: Object.values(activityTagFields),
    table: Tables.DB_TABLE_ACTIVITY_TAG,
    tableColumns: ActivityTagBrowserCols,
    cardLayout: ActivityTagCard

}