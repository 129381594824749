import {ISchemaField, ISchemaFieldType} from "../../../UIComponents/JSONSchemaEditor/JSONSchemaEditor";
import {IEntity} from "../entities";
import {Tables} from "../tables";
import {fieldType, IField} from "../fields";
import {IEntityTableCol} from "../tableColumns";
import {ICard} from "../cardControls";

export enum EActivityCFFields {
    id, create_date,update_date, title, field_key, field_type
}

export const activityCustomFieldsFields:{[key in EActivityCFFields]: IField} = {
    [EActivityCFFields.id]:{
        field: "id",
        defaultVal: null,
        type: fieldType.textType,
        title: "id"
    },
    [EActivityCFFields.create_date]: {
        field: "create_date",
        defaultVal: () => { return (new Date()).toISOString() },
        type: fieldType.dateType,
        title: "תאריך יצירה"
    },
    [EActivityCFFields.update_date]: {
        field: "update_date",
        defaultVal: () => { return (new Date()).toISOString() },
        type: fieldType.dateType,
        title: "תאריך עדכון"
    },
    [EActivityCFFields.title]: {
        field: "title",
        validate: [validateTitleFormat],
        defaultVal: "",
        type: fieldType.textType,
        title: "שם שדה"
    },
    [EActivityCFFields.field_key]: {
        field: "field_key",
        validate: [validateFieldKeyFormat],
        defaultVal: "",
        type: fieldType.textType,
        title: "מפתח ייחודי"
    },
    [EActivityCFFields.field_type]: {
        field: "field_type",
        defaultVal: ()=>{
                return {
                isRoot: true,
                isArray: false,
                isObject: false,
                isValid: true,
                fieldKey: '',
                fieldTitle: '',
                fieldType: ISchemaFieldType[ISchemaFieldType.stringField],
                properties: [],
                disableEdits: false,
                lockEdits: false
            }
        }, validate: [validateSchema],
        type: fieldType.jsonSchema,
        title: "סכמה"
    },
};

function validateTitleFormat(title:string) {
    const valid = title && title.length;
    return {valid: !!valid, err: valid ? undefined : "שם שדה לא תקין"}
}

function validateFieldKeyFormat(field_key:string) {
    const valid = field_key && field_key.length;
    return {valid: !!valid, err: valid ? undefined : "מפתח שדה לא תקין"}
}

function validateSchema(schema:ISchemaField) {
    return {valid: schema.isValid, err: schema.isValid ? undefined : "סכמה לא תקינה"};
}

export const ActivityCFBrowserCols:IEntityTableCol[] = [
    {
        field: activityCustomFieldsFields[EActivityCFFields.title],
        readonly: true,
        defaultWidth: 10,
    },
    {
        field: activityCustomFieldsFields[EActivityCFFields.field_key],
        readonly: true,
        defaultWidth: 10,
    },
]

export const ActivityCFCard:ICard = {
    sections: [
        {
            compact: true,
            hideOnNew: true,
            fields: [
                {
                    field: activityCustomFieldsFields[EActivityCFFields.id],
                    readonly: true
                },
                {
                    field: activityCustomFieldsFields[EActivityCFFields.create_date],
                    readonly: true
                },
                {
                    field: activityCustomFieldsFields[EActivityCFFields.update_date],
                    readonly: true
                }
            ]
        },
        {
            fields: [
                {field: activityCustomFieldsFields[EActivityCFFields.title]},
                {field: activityCustomFieldsFields[EActivityCFFields.field_key]},
            ]
        },
        {
            fields: [
                {field: activityCustomFieldsFields[EActivityCFFields.field_type]},
            ]
        },
    ],
    relatedEntities: []
}

export interface ICustomActivityField {
    id: number,
    title: string,
    create_date: Date,
    update_date: Date,
    field_type: ISchemaField,
    field_key: string,
    disabled: boolean
}

export const ActivityCustomFieldEntity:IEntity = {
    browsingPage: {
        suffix: "custom-activity-fields",
        title: "שדות פעילות",
        newItemLabel: "שדה חדש"
    },
    cardPage: {
        suffix: "custom-activity-field",
        title: "שדה פעילות",
    },
    endpoint: "customActivityFields",
    fields: Object.values(activityCustomFieldsFields),
    table: Tables.DB_TABLE_ACTIVITY_CUSTOM_FIELDS,
    tableColumns: ActivityCFBrowserCols,
    cardLayout: ActivityCFCard
}