import React from 'react';
import ExcelUploader from '../../UIComponents/ExcelUploader/ExcelUploader'

interface IProps {
    onDataLoad: (data:any[][])=>any
}

interface IState {
    contactsData: any[][],
    fileUploaded: boolean,
    fieldsMatched: boolean
}

interface IContactProposal {
    fields: {[field: string]: any},
    valid?: boolean,
    existing?: boolean
}

export default class ContactsUploader extends React.Component <IProps, IState>  {
    constructor(props:IProps) {
        super(props);
        this.state = {
            contactsData: [],
            fileUploaded: false,
            fieldsMatched: false
        };
    }

    async componentDidMount() {
    }


    loadTableDataFromCsv = (data: any[][])=>{
        data = data.map(r=>{
            return r.map(c=>{
                let val = c;
                try {
                    val = JSON.parse(val)
                }
                catch(e){
                    val = c;
                }
                return val;
            })
        })
        this.setState({contactsData: data, fileUploaded: true, fieldsMatched: false}, ()=>{
            this.props.onDataLoad(data);
        })
    };


    render() {
        const fileManager = <div>
            <span className={"file-uploader-buttons-wrap"}>
			    <ExcelUploader
                    onSelect={(data)=> {this.loadTableDataFromCsv(data)}}
                    labelText={
                    !this.state.contactsData.length ?
                        "העלאת רשומות מקובץ" :
                        "החלפה ברשומות מקובץ"
                }/>
		    </span>
        </div>;
        return (
            <div>
                {fileManager}
            </div>
        )
    }

}