import React from 'react';
import "./MultiSelect.scss";

export default class MultiSelect extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			values: props.values,
			options: props.options,
			labelIndex: props.labelIndex,
			valueIndex: props.valueIndex,
			disableIndex: props.disableIndex,
			disabled: props.disabled,
			onChange: props.onChange
		};
	}

	static getDerivedStateFromProps(nextProps, state) {
		return {
			values: nextProps.values,
			options: nextProps.options,
			labelIndex: nextProps.labelIndex,
			valueIndex: nextProps.valueIndex,
			disableIndex: nextProps.disableIndex,
			disabled: nextProps.disabled,
			onChange: nextProps.onChange
		};
	}

	handleChange(event, val)
	{
		let values = this.state.values.slice();
		values = values ? values : [];
		if(event.target.checked){
			values.push(val[this.state.valueIndex])
		}
		else{
			values = values.filter((x)=> x !== val[this.state.valueIndex])
		}
		this.setState({values: values}, ()=>{
			this.state.onChange(values);
		});
	}
	render() {
		const values = this.state.values.slice();
		const options = this.state.options.map((val, i) =>
				<div key={i}>
					<label className="multi-select-option">
						<input type="checkbox" disabled={this.state.disabled || val[this.state.disableIndex]} checked={values.includes(val[this.state.valueIndex])} onChange={(event)=>this.handleChange(event, val)}/>
						{val[this.state.labelIndex]}
					</label>
				</div>);
		return (
			<div>
				{options}
			</div>
		)
	}
}
