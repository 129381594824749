import React from 'react';
import './lib/variables.scss'
import './lib/global.scss'
import './lib/layout.scss'
import './lib/buttons.scss'
import './lib/classes.scss'
import { BrowserRouter as Router, Route, Routes  } from "react-router-dom";
import PubSub from 'pubsub-js';
import events from './lib/events';
import Alert from './UIComponents/Alert/Alert'
import NoMatch from "./pages/404";
import Login from "./pages/Login";
import {ENTITIES} from "./services/dataManagement/entities";
import EntityBrowserPage from "./pages/EntityBrowserPage";
import EntityCardPage from "./pages/EntityCardPage";
import ImportContacts from "./pages/ImportContacts";
import Voting from "./pages/voting/Voting";
import VotingResults from "./pages/voting/VotingResults";
import { role } from "./services/interfaces";
import server from './services/server';

interface IProps {}
interface IState {
  userRole: role|null,
  alertQueue: any[]
}

export default class App extends React.Component <IProps, IState> {
  constructor(props:IProps) {
    super(props);
    this.state = {
      userRole: null,
      alertQueue: []
    };
    PubSub.subscribe(events.alert, (msg: string, data: any) => {
      this.alert(data)
    });
    PubSub.subscribe(events.clearAlert, (msg: string, data: any) => {
      this.clearAlert(data)
    });

    server.get('authenticate/user_role').then(res => 
      this.setState({userRole: res}))
  }

  componentWillUnmount() {
    PubSub.clearAllSubscriptions();
  }

  alert = (alert:any) => {
    let alertQueue = this.state.alertQueue.slice();
    if(alert.flush){
      alertQueue = [alert];
    }
    else{
      alertQueue.push(alert);
    }
    this.setState({alertQueue});
  };

  clearAlert = (options:any) => {
    let alertQueue = this.state.alertQueue.slice();
    if(options.clearAll){
      alertQueue = [];
    }
    else{
      alertQueue.splice(0, 1);
    }
    this.setState({alertQueue});
  };

  saveUserRole = (role:role) => {
    console.log('saving: ' + role);
    
    this.setState({userRole: role})
  }


  render() {
    return (
        <Router>
          <div dir="rtl">
            <meta/>
            <Routes >
              {Object.entries(ENTITIES).map(([e, entity])=>{
                return <React.Fragment key={"page" + e}>
                  <Route key={"browse_" + e} path={("/"+entity.browsingPage.suffix)} element={<EntityBrowserPage key={e} entity={e} userRole={this.state.userRole}/>}/>
                  <Route key={"browse_" + e} path={("/"+entity.cardPage.suffix)} element={<EntityCardPage key={e} entity={e} userRole={this.state.userRole}/>}/>
                </React.Fragment>
              })}
              <Route path="/" element={<Login saveUserRole={this.saveUserRole}/>}/>
              <Route path="/login" element={<Login saveUserRole={this.saveUserRole}/>}/>
              <Route path="/import-contacts" element={<ImportContacts/>}/>
              <Route path="/voting" element={<Voting/>}/>
              <Route path="/votingResults" element={<VotingResults/>}/>
              <Route path="/*" element={<NoMatch/>}/>
            </Routes >
            <Alert setQueue={(alertQueue:any)=>this.setState({alertQueue})} queue={this.state.alertQueue}/>
          </div>
        </Router>
    );
  }
}
