import React from 'react';
import "./PageNav.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretLeft, faCaretRight} from "@fortawesome/free-solid-svg-icons";

interface IProps {
    currPage: number | string,
    pageCount: number | string,
    goToPage: (page:number)=>void
}

interface IState {}

export default class PageNav extends React.Component<IProps, IState> {
    constructor(props:IProps) {
        super(props);
        this.state = {
            goToPage: props.goToPage
        };
    }
    static getDerivedStateFromProps(nextProps: IProps) {
        return {
            goToPage: nextProps.goToPage
        };
    }

    goToPage(p:number|string) {
        const pageCount = typeof this.props.pageCount === "string" ? parseInt(this.props.pageCount) : this.props.pageCount;
        const page =  typeof p === "string" ? parseInt(p) : p
        if(page >= 0 && page < pageCount) {
            this.props.goToPage(page);
        }
    }

    render() {
        const pageIndex = typeof this.props.currPage === "string" ? parseInt(this.props.currPage) : this.props.currPage;
        const pageCount = typeof this.props.pageCount === "string" ? parseInt(this.props.pageCount) : this.props.pageCount;
        return <div className="page-nav-wrap">
            <div className="list-page-nav" dir="ltr">
                <button
                    type={"button"}
                    className="page-director clickable"
                    onClick={()=>{this.goToPage(pageIndex - 1)}}
                    disabled={pageIndex < 1}
                >
                    <FontAwesomeIcon icon={faCaretLeft}/>
                </button>
                <div className="page-selection">
                    {pageIndex - 2 > 0 ?
                        <React.Fragment>
                            <button type="button" className="page-director clickable" onClick={()=>{this.goToPage(0)}}>1</button>
                            <span>...</span>
                        </React.Fragment>
                        : ""
                    }
                    {pageIndex > 1 ?
                        <button type="button" className="page-director clickable" onClick={()=>{this.goToPage(pageIndex - 2)}}>{pageIndex - 1}</button>
                        : ""
                    }
                    {pageIndex > 0 ?
                        <button type="button" className="page-director clickable" onClick={()=>{this.goToPage(pageIndex - 1)}}>{pageIndex}</button>
                        : ""
                    }
                    <span className="page-director curr-page">{pageIndex + 1}</span>
                    {pageIndex + 1 < pageCount ?
                        <button type="button" className="page-director clickable" onClick={()=>{this.goToPage(pageIndex + 1)}}>{pageIndex + 2}</button>
                        : ""
                    }
                    {pageIndex + 2 < pageCount?
                        <button type="button" className="page-director clickable" onClick={()=>{this.goToPage(pageIndex + 2)}}>{pageIndex + 3}</button>
                        : ""
                    }
                    {pageIndex + 3 < pageCount ?
                        <React.Fragment>
                            <span>...</span>
                            <button type="button" className="page-director clickable" onClick={()=>{this.goToPage(pageCount - 1)}}>{pageCount}</button>
                        </React.Fragment>
                        : ""
                    }
                </div>
                <button
                    type={"button"}
                    className="page-director clickable"
                    onClick={()=>{this.goToPage(pageIndex + 1)}}
                    disabled={pageIndex >= (pageCount - 1)}
                >
                    <FontAwesomeIcon icon={faCaretRight}/>
                </button>
            </div>
            </div>;
    }
}
