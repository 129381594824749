import React from 'react';
import './LoadSpinner.scss';

interface IProps{
    radius?: number,
    visibility: boolean,
    children?: JSX.Element,
    align?: string
}

interface IState{}

export default class LoadSpinner extends React.Component<IProps, IState>{
    constructor(props:IProps) {
        super(props);
    }
    render() {
        const radius = this.props.radius ? this.props.radius : 5;
        return this.props.visibility ? (
            <div className={"spinner-wrap " + (this.props.align === "center" ? "centered" : "")}>
                <div className="load-spinner" style={{width: radius + "em", height: radius + "em"}}>
                    {this.props.children}
                </div>
            </div>
        ):<div/>
    }
}
