import React from 'react'
import './JSONSchemaEditor.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";

interface IProps {
    data: ISchemaField,
    onChange: (data: ISchemaField, fieldIndex?: number) => void,
    inArray?: boolean
}

export interface ISchemaField {
    isRoot: boolean,
    isArray: boolean,
    isObject: boolean,
    isValid: boolean,
    fieldKey: string,
    fieldTitle: string,
    fieldType: string,
    properties: ISchemaField[]
    disableEdits: boolean,
    lockEdits: boolean,
    noDuplicates?: boolean
}

export enum ISchemaFieldType {
    objectField, stringField, dateField, numberField, cityField, booleanField
}

interface IState {
    data: ISchemaField,
    onChange: (data: ISchemaField) => void,
}


export default class JSONSchemaEditor extends React.Component <IProps, IState> {
    constructor(props: IProps,) {
        super(props);
        this.state = {
            data: props.data,
            onChange: props.onChange,
        };
        console.log(props.data)
    }

    componentDidMount() {
    }

    static getDerivedStateFromProps(nextProps: IProps, state: IState) {
        return {
            data: nextProps.data,
            onChange: nextProps.onChange,
        };
    }

    toggleIsArray = (value: boolean) => {
        const fieldData = this.state.data;
        fieldData.isArray = value;
        this.setState({data: fieldData}, () => {
            this.onChange();
        });
    };

    toggleNoDuplicates = (value: boolean) => {
        const fieldData = this.state.data;
        fieldData.noDuplicates = value;
        this.setState({data: fieldData}, () => {
            this.onChange();
        });
    };

    editFieldKey = (value: string) => {
        const fieldData = this.state.data;
        fieldData.fieldKey = value;
        this.setState({data: fieldData}, () => {
            this.onChange();
        });
    };

    editFieldTitle = (value: string) => {
        const fieldData = this.state.data;
        fieldData.fieldTitle = value;
        this.setState({data: fieldData}, () => {
            this.onChange();
        });
    };

    editFieldType = (value: string) => {
        const fieldData = this.state.data;
        fieldData.fieldType = value;
        fieldData.isObject = value === ISchemaFieldType[ISchemaFieldType.objectField];
        if (!fieldData.isObject) {
            fieldData.properties = [];
        }
        this.setState({data: fieldData}, () => {
            this.onChange();
        });
    };

    addNewProperty = () => {
        const fieldData = this.state.data;
        const properties = fieldData.properties.slice();
        const newProperty: ISchemaField = {
            isRoot: false,
            isArray: false,
            isObject: false,
            isValid: false,
            fieldKey: '',
            fieldTitle: '',
            fieldType: ISchemaFieldType[ISchemaFieldType.stringField],
            properties: [],
            disableEdits: false,
            lockEdits: false
        };
        properties.push(newProperty);
        fieldData.properties = properties;
        this.setState({data: fieldData}, () => {
            this.onChange();
        });
    };

    deleteProperty = (index: number) => {
        const fieldData = this.state.data;
        const properties = fieldData.properties.slice();
        if (index < 0 || index >= properties.length) {
            return;
        }
        properties.splice(index, 1);
        fieldData.properties = properties;
        this.setState({data: fieldData}, () => {
            this.onChange();
        });
    };

    onPropertyEdit = (propertyIndex: number, data: ISchemaField) => {
        const fieldData = this.state.data;
        fieldData.properties[propertyIndex] = data;
        this.setState({data: fieldData}, () => {
            this.onChange();
        });
    };

    onChange = () => {
        const fieldData = this.state.data;
        fieldData.isValid = this.validate();
        this.setState({data: fieldData}, () => {
            this.state.onChange(this.state.data);
        });
    };

    validate = () => {
        const fieldData = this.state.data;
        let valid: boolean = true;
        valid = fieldData.isRoot || (valid && !!fieldData.fieldKey && !!fieldData.fieldKey.length);
        valid = fieldData.isRoot || (valid && !!fieldData.fieldTitle && !!fieldData.fieldTitle.length);
        if(fieldData.properties && fieldData.properties.length) {
            valid = valid && ((arr: string[]) => {
                return arr.length === new Set(arr).size
            })(fieldData.properties.map((f) => f.fieldKey));
            fieldData.properties.forEach(p => {
                valid = valid && p.isValid;
            });
        }
        return valid;
    };


    render() {
        const data = this.state.data;
        const fieldTypes = [
            {ft: ISchemaFieldType[ISchemaFieldType.objectField], label: "אובייקט"},
            {ft: ISchemaFieldType[ISchemaFieldType.stringField], label: "טקסט"},
            {ft: ISchemaFieldType[ISchemaFieldType.numberField], label: "מספר"},
            {ft: ISchemaFieldType[ISchemaFieldType.dateField], label: "תאריך"},
            {ft: ISchemaFieldType[ISchemaFieldType.booleanField], label: "בוליאני (כן/לא)"},
        ];
        return (
            <div className={"schema-editor"}>
                <label>
                    <span>מערך?</span>
                    <input type="checkbox" checked={data.isArray} onChange={(e) => {
                        this.toggleIsArray(e.target.checked)
                    }}/>
                </label>
                {
                    data.isRoot ? null : <React.Fragment>
                        <label>
                            <span>מפתח</span>
                            <input type={"text"} value={data.fieldKey} onChange={(e) => {
                                this.editFieldKey(e.target.value)
                            }}/>
                        </label>
                        <label>
                            <span>כותרת</span>
                            <input type={"text"} value={data.fieldTitle} onChange={(e) => {
                                this.editFieldTitle(e.target.value)
                            }}/>
                        </label>
                    </React.Fragment>
                }
                <label>
                    <span>טיפוס</span>
                    <select value={data.fieldType} onChange={(e) => {
                        this.editFieldType(e.target.value)
                    }}>
                        {fieldTypes.map((f, i)=>{
                            return <option value={f.ft} key={"field)type_option_"+i}>{f.label}</option>
                        })}
                    </select>
                </label>
                {
                    (!data.isObject && !data.isArray && this.props.inArray) ?
                        <label>
                            <span>מזהה ייחודי (לדרוס נתון קודם במקרה של כפילות במערך)</span>
                            <input type="checkbox" checked={data.noDuplicates} onChange={(e) => {
                                this.toggleNoDuplicates(e.target.checked)
                            }}/>
                        </label>
                        : null
                }
                {
                    data.isObject ?
                        <React.Fragment>
                            <span>תתי-שדות:</span>
                            {
                                data.properties.map((p, i) => {
                                    return <span className={"embedded-object"} key={"property_"+i}>
                                        <button type={"button"} onClick={()=>{this.deleteProperty(i)}}>
                                            <FontAwesomeIcon icon={faTimes}/>
                                        </button>
                                        <JSONSchemaEditor
                                            key={("subfield_" + i)} data={p}
                                            onChange={(data: ISchemaField) => {
                                                this.onPropertyEdit(i, data);
                                            }}
                                            inArray={this.props.inArray || this.state.data.isArray}
                                        />
                                    </span>
                                })
                            }
                            <button type={"button"} onClick={()=>{this.addNewProperty()}}>
                                <FontAwesomeIcon icon={faPlus}/>
                            </button>
                        </React.Fragment>
                        : null
                }
            </div>
        )
    }
}

