import {IDBQuery} from "../../../UIComponents/QueryBuilder/QueryBuilder";
import {IEntity} from "../entities";
import {Tables} from "../tables";
import {fieldType, IField} from "../fields";
import {ICard} from "../cardControls";
import {E_ENTITIES} from "../entityDefinitions";
import {IEntityTableCol} from "../tableColumns";

export enum EContactGroupFields {
    id, create_date,update_date, title, join_query, member_count
}

export const contactGroupFields:{[key in EContactGroupFields]: IField} = {
    [EContactGroupFields.id]:{
        field: "id",
        defaultVal: null,
        type: fieldType.textType,
        title: "id"
    },
    [EContactGroupFields.create_date]: {
        field: "create_date",
        defaultVal: () => { return (new Date()).toISOString() },
        type: fieldType.dateType,
        title: "תאריך יצירה"
    },
    [EContactGroupFields.update_date]: {
        field: "update_date",
        defaultVal: () => { return (new Date()).toISOString() },
        type: fieldType.dateType,
        title: "תאריך עדכון"
    },
    [EContactGroupFields.title]: {
        field: "title",
        validate: [validateTitleFormat],
        defaultVal: "",
        type: fieldType.textType,
        title: "שם קבוצה"
    },
    [EContactGroupFields.join_query]: {
        field: "join_query",
        defaultVal: "",
        type: fieldType.contactsQueryType,
        title: "צירוף אוטומטי לפי שאילתה"
    },
    [EContactGroupFields.member_count]: {
        field: "member_count",
        defaultVal: "",
        type: fieldType.textType,
        title: "מספר חברים"
    },
};

export const ContactGroupsBrowserCols:IEntityTableCol[] = [
    {
        field: contactGroupFields[EContactGroupFields.create_date],
        readonly: true,
        defaultWidth: 2,
    },
    {
        field: contactGroupFields[EContactGroupFields.title],
        readonly: true,
        defaultWidth: 10,
    },
    {
        field: contactGroupFields[EContactGroupFields.member_count],
        readonly: true,
        defaultWidth: 1,
    },
]

export const ContactGroupCard:ICard = {
    sections: [
        {
            compact: true,
            hideOnNew: true,
            fields: [
                {
                    field: contactGroupFields[EContactGroupFields.id],
                    readonly: true
                },
                {
                    field: contactGroupFields[EContactGroupFields.create_date],
                    readonly: true
                },
                {
                    field: contactGroupFields[EContactGroupFields.update_date],
                    readonly: true
                }
            ]
        },
        {
            fields: [
                {field: contactGroupFields[EContactGroupFields.title]},
            ]
        },
        {
            fields: [
                {field: contactGroupFields[EContactGroupFields.join_query]},
            ]
        },
    ],
    relatedEntities: [
        {
            title: "חברי קבוצה",
            entity: E_ENTITIES.contacts,
            query: (id:number)=> {
                return {
                    "table": Tables[Tables.DB_TABLE_CONTACT],
                    "conditions": [
                        {
                            "id": "",
                            "s": false,
                            "t": 1,
                            "l": 0,
                            "subject": "j_membership_in_contact_group",
                            "filter": null,
                            "value": {
                                "join": "membership_in_contact_group",
                                "innerQuery": {
                                    "conditions": [
                                        {
                                            "id": "",
                                            "s": true,
                                            "t": 1,
                                            "l": 0,
                                            "subject": "c_id",
                                            "filter": "cg_in",
                                            "value": [ id ]
                                        }
                                    ],
                                    "table": Tables[Tables.DB_TABLE_CONTACT_GROUP]
                                }
                            }
                        }
                    ]
                }
            }
        },
    ]
}

function validateTitleFormat(title:string) {
    const valid = title && title.length;
    return {valid: !!valid, err: valid ? undefined : "שם קבוצה לא תקין"}
}

export interface IContactGroup {
    id: number,
    title: string,
    create_date: Date,
    update_date: Date,
    join_query: IDBQuery | null,
}

export const ContactGroupEntity:IEntity = {
    browsingPage: {
        suffix: "contact-groups",
        title: "קבוצות אנשי קשר",
        newItemLabel: "קבוצה"
    },
    cardPage: {
        suffix: "contact-group",
        title: "קבוצת אנשי קשר",
    },
    endpoint: "contactGroups",
    fields: Object.values(contactGroupFields),
    table: Tables.DB_TABLE_CONTACT_GROUP,
    tableColumns: ContactGroupsBrowserCols,
    cardLayout: ContactGroupCard
}