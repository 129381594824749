import React from 'react';
import Popup from "../Popup/Popup";
import "../ApprovalPopup/ApprovalPopup.scss"

interface IProps{
    buttonLabel: string,
    buttonClassNames: string[],
    title: string,
    description?: string,
    onApprove: ()=>void,
    approveLabel?: string,
    cancelLabel?: string,
    requireTyping?: string
}

interface IState{
    popupVisibility: boolean,
    typedApproval: string
}

export default class ApprovalPopup extends React.Component<IProps, IState> {
    constructor(props:IProps) {
        super(props);
        this.state = {
            popupVisibility: false,
            typedApproval: ""
        };
    }

    render() {
        return(
            <span className={"approval-popup"}>
                <button type={"button"}
                        onClick={()=>{this.setState({popupVisibility: true})}}
                        className={this.props.buttonClassNames.join(" ")}
                >
                    {this.props.buttonLabel}
                </button>
                <Popup visibility={this.state.popupVisibility}
                       toggleVisibility={()=>{
                           this.setState({popupVisibility: !this.state.popupVisibility})
                       }}
                >
                    <h1>{this.props.title}</h1>
                    <p>{this.props.description ? this.props.description : ""}</p>
                    {
                        this.props.requireTyping ?
                            <span>
                                <label>{"הקלידו " + this.props.requireTyping + " על מנת לאשר את הפעולה"}</label>
                                <input
                                    type={"text"}
                                    value={this.state.typedApproval}
                                    onChange={(e)=>{
                                        this.setState({typedApproval: e.target.value})
                                    }}
                                />
                            </span>
                            : null
                    }
                    <div className={"buttons-wrap"}>
                        <button
                            type={"button"}
                            className={"big-button"}
                            onClick={()=>{this.props.onApprove()}}
                            disabled={!!this.props.requireTyping && (this.props.requireTyping !== this.state.typedApproval)}
                        >
                            {this.props.approveLabel ? this.props.approveLabel : "אישור"}
                        </button>
                        <button
                            type={"button"}
                            className={"big-button"}
                            onClick={()=>{this.setState({popupVisibility: false})}}
                        >
                            {this.props.cancelLabel ? this.props.cancelLabel : "ביטול"}
                        </button>
                    </div>
                </Popup>
            </span>
        );
    }
}
