import React from 'react';
import {Tables, DBTablesDict, IDBTable} from "../../services/dataManagement/tables";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import QueryCondition from "./QueryCondition";
import "./QueryBuilder.scss"
import {cn} from "../../services/utils";
import {faTimes} from "@fortawesome/free-solid-svg-icons";

interface IProps {
    table: Tables,
    onChange: (q:IDBQuery)=>void,
    query: IDBQuery,
    ungroup?: Function,
    enableJoins?: boolean,
    onSubmit?: () => void,
    nested: boolean
}

interface IState {
    table: Tables,
    query: IDBQuery,
    ungroup?: Function,
    enableJoins: boolean,
}

export interface IDBQuery {
    table: string,
    conditions: ICondition[],
}

export enum logicConnector {AND, OR}

export enum conditionTypes {GROUP, COLUMN}

export interface ICondition {
    id: string,
    s: boolean,
    t: conditionTypes,
    l: logicConnector,
}

export interface IGroupCondition extends ICondition {
    innerQuery: IDBQuery,
}

export interface IColumnCondition extends ICondition {
    subject: string | null,
    filter: string | null,
    value: any,
}

export default class QueryBuilder extends React.Component <IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            table: props.table,
            query: props.query ? props.query : {conditions:[], table:Tables[props.table]},
            ungroup: props.ungroup,
            enableJoins: !!props.enableJoins
        };
    }

    async componentDidMount() {
        if(this.props.nested && (!this.state.query.conditions || !this.state.query.conditions.length)){
            this.addCondition();
        }
    }

    addCondition = () => {
        const query = this.state.query;
        const conditions = query.conditions.slice();
        conditions.push({
            id: "filter_" + Math.random().toString(36).slice(-10),
            s: false,
            t: conditionTypes.COLUMN,
            l: logicConnector.AND
        });
        query.conditions = conditions;
        this.setState({query}, () => {
            this.props.onChange(query);
        });
    };

    onColumnConditionEdit = (conditionIndex: number, c: string | null, f: string | null, v: any) => {
        const query = this.state.query;
        const conditions = this.state.query.conditions.slice();
        if (conditions[conditionIndex].t !== conditionTypes.COLUMN) {
            return;
        }
        const condition = (conditions[conditionIndex] as IColumnCondition);
        condition.subject = c ? c : null;
        condition.filter = f ? f : null;
        condition.value = v;
        query.conditions = conditions;
        this.setState({query}, () => {
            this.props.onChange(query);
        });
    };

    toggleLogicalRelation = (conditionIndex: number) => {
        const query = this.state.query;
        const conditions = this.state.query.conditions.slice();
        if (conditions[conditionIndex].t !== conditionTypes.COLUMN) {
            return;
        }
        const currOp = conditions[conditionIndex].l;
        conditions[conditionIndex].l = currOp === logicConnector.AND ? logicConnector.OR : logicConnector.AND;
        query.conditions = conditions;
        this.setState({query}, () => {
            this.props.onChange(query);
        });
    };

    toggleSelection = (conditionIndex: number) => {
        const query = this.state.query;
        if(!query){return}
        const conditions = this.state.query.conditions.slice();
        if(!conditions[conditionIndex]){return}
        conditions[conditionIndex].s = !conditions[conditionIndex].s;
        query.conditions = conditions;
        this.setState({query}, () => {
            this.props.onChange(query);
        });
    };

    setGroupConditionValue = (value: any, groupIndex: number) => {
        const query = this.state.query;
        const conditions = this.state.query.conditions.slice();
        if (conditions[groupIndex].t !== conditionTypes.GROUP) {
            return;
        }
        (conditions[groupIndex] as IGroupCondition).innerQuery = value;
        query.conditions = conditions;
        this.setState({query}, () => {
            this.props.onChange(query);
        });
    };

    groupConditions = () => {
        const query = this.state.query;
        const conditions = this.state.query.conditions.slice();
        const insertAt = conditions
            .map((v, i) => {
                return {index: i, selected: v.s}
            })
            .filter((v) => v.selected)[0].index;
        const remaining = conditions.filter(c => !c.s);
        const group: IGroupCondition = {
            s: true,
            t: conditionTypes.GROUP,
            l: logicConnector.AND,
            id: "group_" + Math.random().toString(36).slice(-10),
            innerQuery: {table: Tables[this.state.table], conditions:conditions
                    .filter(c => c.s)
                    .map(c => {
                        c.s = false;
                        return c
                    })}
        };
        remaining.splice(insertAt,0, group);
        query.conditions = remaining;
        this.setState({query}, async () => {
            await this.props.onChange(query);
            await this.onSubmit();
        })
    };

    ungroupConditions = (groupIndex: number) => {
        const query = this.state.query;
        const conditions = this.state.query.conditions.slice();
        if (conditions[groupIndex].t !== conditionTypes.GROUP) {
            return;
        }
        const group = conditions.splice(groupIndex, 1)[0] as IGroupCondition;
        conditions.splice(groupIndex, 0, ...group.innerQuery.conditions);
        query.conditions = conditions;
        this.setState({query}, async () => {
            await this.props.onChange(query);
            await this.onSubmit();
        })
    };

    removeCondition = (conditionIndex: number) => {
        const query = this.state.query;
        if(!query){return}
        const conditions = query.conditions.slice();
        if(!conditions.length){return}
        if(conditionIndex < 0 || conditionIndex >= conditions.length){return}
        conditions.splice(conditionIndex, 1);
        query.conditions = conditions;
        this.setState({query}, async () => {
            await this.props.onChange(query);
            await this.onSubmit();
        })
    };

    onSubmit = () => {
        return this.props.onSubmit ? this.props.onSubmit() : null;
    };

    render() {
        const table: (IDBTable | undefined) = DBTablesDict[this.state.table];
        if (!table) {
            return null;
        }
        const query = this.state.query ? this.state.query : null;
        const conditions = query ? query.conditions.slice() : [];
        return <div className={"query-builder-wrap"}>
            <div className={"query-controls-bar"}>
                {
                    (conditions.filter(c => c.s).length > 1) ?
                    <button type={"button"} className="small-purple-button group-conditions-button"
                            disabled={ !(conditions.filter(c => c.s).length > 1) }
                            title={"צירוף התנאים המסומנים לקבוצה"}
                            onClick={() => {
                                this.groupConditions()
                            }}>
                    קיבוץ תנאים
                    </button> : null
                }
                {
                    this.state.ungroup ?
                        <button type={"button"}
                                title={"פירוק קיבוץ תנאים"}
                                className="small-purple-button ungroup-conditions-button"
                                onClick={(e) => {
                                    if(this.state.ungroup){
                                        this.state.ungroup()
                                    }
                                    e.stopPropagation();
                                }}>פירוק קיבוץ
                        </button> : null}
            </div>
            {conditions.map((q, i) => {
                const subject = (q as IColumnCondition).subject;
                const filter = (q as IColumnCondition).filter;
                return <React.Fragment key={q.id}>
                    <div onClick={(e) => {
                        this.toggleSelection(i);
                        e.stopPropagation();
                    }} className={
                        cn({"condition-builder-wrap": true, "selected-condition": (q as IColumnCondition).s})}>
                        {
                            q.t === conditionTypes.COLUMN ?
                                <div className={"single-condition-wrap"}>
                                    <button type={"button"}
                                            className={"delete-condition-button"}
                                            title={"הסרת תנאי"}
                                            onClick={()=>{this.removeCondition(i)}}
                                    >
                                        <FontAwesomeIcon icon={faTimes}/>
                                    </button>
                                    <QueryCondition
                                        table={Tables[this.state.table]}
                                        subject={subject}
                                        filter={filter}
                                        value={(q as IColumnCondition).value}
                                        onFilterChange={(c: string | null, f: string | null, v: any) => {
                                            this.onColumnConditionEdit(i, c, f, v)
                                        }}
                                        onSubmit={()=>{
                                            this.onSubmit();
                                        }}
                                    />
                                </div>
                                : <div className={"condition-group"}>
                                    <QueryBuilder
                                        table={this.state.table}
                                        ungroup={()=>{this.ungroupConditions(i)} }
                                        onChange={(v: any) => {
                                            this.setGroupConditionValue(v, i)
                                        }}
                                        query={(q as IGroupCondition).innerQuery}
                                        enableJoins={false}
                                        onSubmit={()=>{this.onSubmit()}}
                                        nested={false}
                                    />
                            </div>
                        }
                    </div>
                    {
                        i < (conditions.length - 1) ?
                            <button type={"button"} title={"החלפת קשר לוגי בין התנאים"} className={"condition-relation"} onClick={(e) => {
                                this.toggleLogicalRelation(i); e.stopPropagation();
                            }}>{
                                q.l === logicConnector.AND ? "וגם" : "או"
                            }</button>
                            : null
                    }
                </React.Fragment>
            })}
            <button title={"הוספת תנאי"} type={"button"} onClick={() => {
                this.addCondition()
            }}>
                <FontAwesomeIcon icon={faPlus}/>
            </button>
        </div>
    }
}
