import React from 'react'
import './JSONQueryEditor.scss';
import {ISchemaField} from "../JSONSchemaEditor/JSONSchemaEditor";
import JSONQueryEditor from "./JSONQueryEditor";
import server from "../../services/server";

interface IProps {
    data: any,
    onFilterChange: (filter: JSONFilter | string | null) => void,
    onChange: (data: any) => void,
    onSubmit?: () => void,
    inArray ?: boolean,
    arrayIndex ?: number,
    filter: JSONFilter | string | null,
    selectedFilter ?: string
}


interface IState {
    data: any,
    schema: ISchemaField | null,
    onFilterChange: (filter: JSONFilter | string | null) => void,
    onChange: (data: any) => void,
    onSubmit?: () => void,
    inArray ?: boolean,
    arrayIndex ?: number,
    filter: JSONFilter | string | null,
    selectedFilter ?: string
}

interface JSONFilter {[key: string]: (JSONFilter | string)}


export default class CustomContactFieldsQueryEditor extends React.Component <IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            data: props.data,
            schema: null,
            onFilterChange: props.onFilterChange,
            onChange: props.onChange,
            onSubmit: props.onSubmit,
            inArray: props.inArray,
            filter: props.filter
        };
    }

    componentDidMount() {
        server.get('customContactFields').then((data:any)=>{
            this.setState({schema: {
                    disableEdits: true,
                    fieldKey: "",
                    fieldTitle: "Custom Fields",
                    fieldType: "objectField",
                    isArray: false,
                    isObject: true,
                    isRoot: true,
                    isValid: true,
                    lockEdits: true,
                    properties: data.map((f: any) => {
                        const cf: ISchemaField = f.field_type;
                        cf.fieldKey = f.field_key;
                        cf.isRoot = false;
                        cf.fieldTitle = f.title
                        return cf;
                    })
                }});
        });
    }

    static getDerivedStateFromProps(nextProps: IProps, state: IState) {
        return {
            data: nextProps.data,
            onChange: nextProps.onChange,
            inArray: nextProps.inArray,
            filter: nextProps.filter
        };
    }

    onSubmit = () => {
      return this.state.onSubmit ? this.state.onSubmit() : null;
    };

    render() {
        return <JSONQueryEditor data={this.state.data}
                                filter={this.state.filter}
                                schema={this.state.schema}
                                onFilterChange={(v) => {
                                    this.state.onFilterChange(v);
                                }}
                                onChange={(v) => {
                                    this.state.onChange(v);
                                }}
                                onSubmit={()=> {this.onSubmit()}}
        />
    }
}

