import React from 'react';
import TopNavBar from '../UIComponents/TopNavBar/TopNavBar';
import {ISchemaField} from "../UIComponents/JSONSchemaEditor/JSONSchemaEditor";
import {ActivityEntity, IActivity} from "../services/dataManagement/entities/activity";
import server from "../services/server";
import LoadSpinner from "../UIComponents/LoadSpinner/LoadSpinner";
import Popup from "../UIComponents/Popup/Popup";

import {E_ENTITIES} from "../services/dataManagement/entityDefinitions";
import EntityPicker from "../UIComponents/TableComponents/EntityPicker/EntityPicker";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faArrowRight, faArrowUp} from "@fortawesome/free-solid-svg-icons";
import "./importContacts/ImportContacts.scss"
import ContactsUploader from "./importContacts/ContactsUploader";
import ContactsFieldsMatcher from "./importContacts/ContactsFieldsMatcher";
import SmartGrid from "../UIComponents/TableComponents/SmartGrid/SmartGrid";
import {cn} from "../services/utils";
import {FIELD_CONTROLS, FIELD_JUSTIFICATION} from "../services/dataManagement/fieldControls";
import {TABLE_COLS_INPUTS} from "../services/dataManagement/tableColumns";
import {fieldType} from "../services/dataManagement/fields";
import PageNav from "../UIComponents/PageNav/PageNav";
import withRouter from "../services/withRouter";

interface IProps {
	location: any,
	navigate: any,
	params: any,
	searchParams: any,
	setSearchParams: any,
}

const PREVIEW_TABLE_PAGE_LEN = 50
enum E_UPLOADER_STAGE {PICK_ACTIVITY, UPLOAD_FILE, MATCH_FIELDS, PREVIEW}

interface IState {
	stage: E_UPLOADER_STAGE,
	activityId: number | null,
	activity: IActivity | null,
	csvData: any[][],
	contacts: {[key:string]: any}[],
	fields: IContactFields[],
	customContactFields: any[] | null,
	awaitingUpload: boolean,
	uploadRes: IUploadResult | null,
	previewPage: number,
}

export interface IContactFields {
	title: string,
	field: string,
	defaultValue: any,
	schema: ISchemaField,
	baseField: boolean,
}

interface IUploadResult {
	insertCount: number,
	updateCount: number,
	discardedRows: number
}

const initState: IState = {
	stage: E_UPLOADER_STAGE.PICK_ACTIVITY,
	fields: [],
	csvData: [],
	contacts: [],
	previewPage: 0,
	customContactFields: null,
	activity: null,
	activityId: null,
	awaitingUpload: false,
	uploadRes: null,
};

class ImportContacts extends React.Component <IProps, IState>  {
constructor(props:IProps) {
	super(props);
	this.state = initState;
}

async componentDidMount() {
	await this.getContactFields();
}

getContactFields = async () => {
	const data = await server.get('customContactFields');
	if(Array.isArray(data)){

		this.setState({customContactFields: data})
	}
};

fetchActivityDetails = () => {
	server.get('activities/'+this.state.activityId)
		.then((activity:IActivity) => {
			const fields = this.state.fields.slice().filter(f=>f.baseField);
			const customFields = this.state.customContactFields ? this.state.customContactFields : [];
			customFields.forEach(f => {
				if(!f){
					return;
				}
				fields.push({
						title: f.title,
						field: f.field_key,
						defaultValue: "",
						schema: f.field_type,
						baseField: false
					})
			});
			this.setState({activity, fields, stage: E_UPLOADER_STAGE.UPLOAD_FILE})
		});

};

setActivity = (activityId:number | null) => {
	this.setState({activityId, activity: null}, ()=>{
		if(activityId !== null){
			this.fetchActivityDetails();
		}
	});
};

previewContacts = (contacts: {[key:string]: any}[], fields: IContactFields[])=>{
	this.setState({contacts, fields, stage: E_UPLOADER_STAGE.PREVIEW, previewPage: 0})
}

handlePreviewEdit(i: number, field: string, val: any) {
	const contacts = this.state.contacts.slice();
	contacts[i][field] = val;
	this.setState({contacts});
}

uploadData = async () => {
	if(this.state.activityId === null || !this.state.contacts.length){
		return;
	}
	this.setState({awaitingUpload: true}, async ()=>{
		const fields = this.state.fields.slice();
		const uploadRes = await server.post("contacts/upload", {
			data: this.state.contacts.slice().map(d=>{
				const contact:{[key:string]: any} = {custom_fields:{}};
				fields.forEach(f=>{
					if(f.baseField){
						contact[f.field] = d[f.field];
					}
					else{
						contact.custom_fields[f.field] = d.custom_fields[f.field];
					}
				});
				return contact;
			}),
			activityId: this.state.activityId
		})
		this.setState({awaitingUpload: false, uploadRes})
	})
}

render() {
	const contactFieldsLoaded = !!this.state.customContactFields;
	const activityId = this.state.activityId;
	const activity = this.state.activity;
	const uploadRes = this.state.uploadRes;

	const activityPicker =
			<EntityPicker
				entity={E_ENTITIES.activities}
				selectPlaceholder={" "}
				onSelect={async (v:number | null)=>{
					await this.setActivity(v);
				}}
				selected={(this.state.activityId || this.state.activityId === 0) ? this.state.activityId : null}
				embedInPopup={false}
				hideQueryEditor={true}
				hideFreeSearch={false}
			/>

	const contactsUploader = <ContactsUploader
		onDataLoad={(csvData)=>{this.setState({csvData, stage: E_UPLOADER_STAGE.MATCH_FIELDS})}}
	/>

	const fieldsMatcher = <ContactsFieldsMatcher
		contactsData={this.state.csvData}
		setContacts={(contacts, fields)=> {
			this.previewContacts(contacts, fields)
		}}
	/>

	const contacts = this.state.contacts.slice();
	const fields = this.state.fields.slice();
	const cols = fields.map(f=>{
		return {
			key: f.field,
			header: <span>{f.title}</span>,
			defaultWidth: f.defaultValue
		}
	});
	const page = this.state.previewPage;
	const rows = contacts.slice(page * PREVIEW_TABLE_PAGE_LEN, (page + 1) * PREVIEW_TABLE_PAGE_LEN).map((r,i)=>{
		const rowIndex = page * PREVIEW_TABLE_PAGE_LEN + i;
		return {
			classNames: cn({"selected-row": !!r.selected}),
			cells: fields.map(f=>{
				let val
				if (f.baseField) val = r[f.field];
				else val = r.custom_fields[f.field]
				return {
					classNames: "",
					content: FIELD_CONTROLS[TABLE_COLS_INPUTS[fieldType.textType]]({
						readonly: false,
						disabled: false,
						dir: FIELD_JUSTIFICATION.AUTO,
						value: val,
						options: {},
						onChange: (val:any) => {this.handlePreviewEdit(rowIndex, f.field, val)}
					}),
					value: val,
				}
			}),
			onClick:()=>{}
		}
	});
	const contactsPreview =
		<React.Fragment>
			<SmartGrid
				cols={cols}
				rows={rows}
			/>
			<PageNav
				currPage={page}
				pageCount={Math.floor(contacts.length/PREVIEW_TABLE_PAGE_LEN)}
				goToPage={(page)=>{this.setState({previewPage: page})}}
			/>
		</React.Fragment>

	const uploadMechanism = <React.Fragment>
		<span className={"submit-contacts-wrap"}>
			<button
				disabled={this.state.awaitingUpload}
				type={"button"}
				className={"big-button"}
				onClick={async ()=>{await this.uploadData()}}
			>
				העלאת אנשי הקשר
			</button>
			<span>ל{activity?.title}</span>
		</span>
		<LoadSpinner visibility={this.state.awaitingUpload} radius={2}/>
		<Popup visibility={!!uploadRes} toggleVisibility={()=>{this.setState(initState, async ()=>{
			this.props.navigate("/" + ActivityEntity.cardPage.suffix + "/?id=" + activityId);
		})}}>
			<h2>הנתונים נקלטו!</h2>
			<p>אנשי קשר חדשים שנוספו: {uploadRes?.insertCount}</p>
			<p>אנשי קשר קיימים שעודכנו: {uploadRes?.updateCount}</p>
			<p>רשומות לא תקינות שנוכו: {uploadRes?.discardedRows}</p>
		</Popup>
	</React.Fragment>

	const stageTitles:{[key in E_UPLOADER_STAGE]: JSX.Element} = {
		[E_UPLOADER_STAGE.PICK_ACTIVITY]:
			<React.Fragment>
				<span>בחירת פעילות</span>
			</React.Fragment>,
		[E_UPLOADER_STAGE.UPLOAD_FILE]: <React.Fragment>
			<button
				type={"button"}
				className={"stage-nav-button back-button"}
				title={"החלפת פעילות"}
				onClick={()=>{this.setState({stage: E_UPLOADER_STAGE.PICK_ACTIVITY})}}
			>
				<FontAwesomeIcon icon={faArrowRight}/>
			</button>
			<span>העלאת קובץ</span>
		</React.Fragment>,
		[E_UPLOADER_STAGE.MATCH_FIELDS]:
			<React.Fragment>
				<button
					type={"button"}
					className={"stage-nav-button back-button"}
					title={"העלאת קובץ אחר"}
					onClick={()=>{this.setState({stage: E_UPLOADER_STAGE.UPLOAD_FILE})}}
				>
					<FontAwesomeIcon icon={faArrowRight}/>
				</button>
				<span>התאמת שדות</span>
			</React.Fragment>,
		[E_UPLOADER_STAGE.PREVIEW]:
			<React.Fragment>
				<button
					type={"button"}
					className={"stage-nav-button back-button"}
					title={"חזרה להתאמת שדות"}
					onClick={()=>{this.setState({stage: E_UPLOADER_STAGE.MATCH_FIELDS})}}
				>
					<FontAwesomeIcon icon={faArrowRight}/>
				</button>
				<span>תצוגה מקדימה וסיום</span>
			</React.Fragment>
	}

	const nextStageNavs:{[key in E_UPLOADER_STAGE]: JSX.Element | null} = {
		[E_UPLOADER_STAGE.PICK_ACTIVITY]:
			this.state.activity ?
			<React.Fragment>
				<button
					type={"button"}
					className={"stage-nav-button next-button"}
					onClick={()=>{this.setState({stage: E_UPLOADER_STAGE.UPLOAD_FILE})}}
				>
					<span>העלאת קובץ</span>
					<FontAwesomeIcon icon={faArrowLeft}/>
				</button>
			</React.Fragment> : null,
		[E_UPLOADER_STAGE.UPLOAD_FILE]:
		this.state.csvData && this.state.csvData.length ?
			<React.Fragment>
				<button
					type={"button"}
					className={"stage-nav-button next-button"}
					onClick={()=>{this.setState({stage: E_UPLOADER_STAGE.MATCH_FIELDS})}}
				>
					<span>התאמת שדות</span>
					<FontAwesomeIcon icon={faArrowLeft}/>
				</button>
			</React.Fragment> : null,
		[E_UPLOADER_STAGE.MATCH_FIELDS]: null,
		[E_UPLOADER_STAGE.PREVIEW]: null
	}
	const stages:{[key in E_UPLOADER_STAGE]: JSX.Element} = {
		[E_UPLOADER_STAGE.PICK_ACTIVITY]: activityPicker,
		[E_UPLOADER_STAGE.UPLOAD_FILE]: contactsUploader,
		[E_UPLOADER_STAGE.MATCH_FIELDS]: fieldsMatcher,
		[E_UPLOADER_STAGE.PREVIEW]: <React.Fragment>{contactsPreview}{uploadMechanism}</React.Fragment>
	}
	const currStage = this.state.stage;
	const stageTitle = stageTitles[currStage];
	const stageManager = stages[currStage]
	const nextStage = nextStageNavs[currStage];
	return (
		<div className={"page-wrap-import-contacts"} dir={"rtl"}>
			<TopNavBar>
				<div className="title-wrap">
					<span className="title-lang">העלאת אנשי קשר</span>
				</div>
			</TopNavBar>
			<div className="content-wrap">
				<h2 className={"contacts-upload-stage-title"}>
					{stageTitle}
				</h2>
				<div>
					{stageManager}
				</div>
				<h2 className={"contacts-upload-next-stage"}>
					{nextStage}
				</h2>
			</div>
		</div>
	)
}
}
export default (withRouter(ImportContacts));