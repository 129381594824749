import {IEntity} from "../entities";
import {Tables} from "../tables";
import {fieldType, IField} from "../fields";
import {IEntityTableCol} from "../tableColumns";
import {ICard} from "../cardControls";
import {E_ENTITIES} from "../entityDefinitions";

export enum EActivityTypeFields {
    id, create_date,update_date, title
}

export const activityTypeFields:{[key in EActivityTypeFields]: IField} = {
    [EActivityTypeFields.id]:{
        field: "id",
        defaultVal: null,
        type: fieldType.textType,
        title: "id"
    },
    [EActivityTypeFields.create_date]: {
        field: "create_date",
        defaultVal: () => { return (new Date()).toISOString() },
        type: fieldType.dateType,
        title: "תאריך יצירה"
    },
    [EActivityTypeFields.update_date]: {
        field: "update_date",
        defaultVal: () => { return (new Date()).toISOString() },
        type: fieldType.dateType,
        title: "תאריך עדכון"
    },
    [EActivityTypeFields.title]: {
        field: "title",
        validate: [validateTitleFormat],
        defaultVal: "",
        type: fieldType.textType,
        title: "כותרת"
    },
};

function validateTitleFormat(title:string) {
    const valid = title && title.length;
    return {valid: !!valid, err: valid ? undefined : "כותרת לא תקינה"}
}

export const ActivityTypeBrowserCols:IEntityTableCol[] = [
    {
        field: activityTypeFields[EActivityTypeFields.title],
        readonly: true,
        defaultWidth: 10,
    },
]

export const ActivityTypeCard:ICard = {
    sections: [
        {
            compact: true,
            hideOnNew: true,
            fields: [
                {
                    field: activityTypeFields[EActivityTypeFields.id],
                    readonly: true
                },
                {
                    field: activityTypeFields[EActivityTypeFields.create_date],
                    readonly: true
                },
                {
                    field: activityTypeFields[EActivityTypeFields.update_date],
                    readonly: true
                }
            ]
        },
        {
            fields: [
                {field: activityTypeFields[EActivityTypeFields.title]},
            ]
        },
    ],
    relatedEntities: [
        {
            title: "פעילויות",
            entity: E_ENTITIES.activities,
            query: (id:number)=> {
                return {
                    "conditions": [
                        {
                            "id": "",
                            "s": false,
                            "t": 1,
                            "l": 0,
                            "subject": "c_activity_type_id",
                            "filter": "aty_in",
                            "value": [
                                id
                            ]
                        }
                    ],
                    "table": Tables[Tables.DB_TABLE_ACTIVITY]
                }
            }
        },
    ]
}

export interface IActivityType {
    id: number,
    create_date: Date,
    title: string,
    custom_activity_fields: string,
}

export const ActivityTypeEntity:IEntity = {
    browsingPage: {
        suffix: "activity-types",
        title: "סוגי פעילות",
        newItemLabel: "סוג פעילות חדש"
    },
    cardPage: {
        suffix: "activity-type",
        title: "סוג פעילות",
    },
    endpoint: "activityTypes",
    fields: Object.values(activityTypeFields),
    table: Tables.DB_TABLE_ACTIVITY_TYPE,
    tableColumns: ActivityTypeBrowserCols,
    cardLayout: ActivityTypeCard
}
