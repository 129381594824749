import React from 'react';
import "./EntityPicker.scss";
import {IDBQuery} from "../../QueryBuilder/QueryBuilder";
import EntityBrowser from "../EntityBrowser/EntityBrowser";
import LoadSpinner from "../../LoadSpinner/LoadSpinner";
import ArrayManager from "../../ArrayManager/ArrayManager";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil, faTimes} from "@fortawesome/free-solid-svg-icons";
import Popup from "../../Popup/Popup";

import {E_ENTITIES} from "../../../services/dataManagement/entityDefinitions";
import {ENTITIES} from "../../../services/dataManagement/entities";
import server from "../../../services/server";

type selectedValue = number | null;

interface IProps {
    entity: E_ENTITIES
    selectPlaceholder?: string,
    noSelectionPlaceholder?: string,
    selected: number | null,
    onSelect: (value:selectedValue)=>void,
    embedInPopup?: boolean,
    readonly ?: boolean,
    disabled ?: boolean,
    onRowClick?: (id:number)=>void,
    currPage?: number,
    searchTerm?: string,
    query?: IDBQuery,
    onQueryChange?: (query:IDBQuery)=>void,
    onPageChange?: (p:number)=>void,
    onSearchTermChange?: (s:string)=>void,
    hideQueryEditor?: boolean,
    hideFreeSearch?: boolean,
}

interface IState {
    valueLabel: string,
    loadingLabels: boolean,
    popupVisibility: boolean,
    currPage: number,
    searchTerm: string,
    query?: IDBQuery,
}

export default (class EntityPicker extends React.Component <IProps, IState>{
    constructor(props:IProps) {
        super(props);
        this.state = {
            valueLabel: "",
            loadingLabels: true,
            popupVisibility: false,
            currPage: props.currPage ? props.currPage : 0,
            searchTerm: props.searchTerm ? props.searchTerm : "",
            query: props.query,
        };
    }

    componentDidMount() {
        return this.getLabels(this.props.selected);
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        //return this.getLabels(this.props.selected);
    }

    onRowClick = async(id: number) => {
        if(this.props.readonly || this.props.disabled){
            return;
        }
        const value = this.props.selected === id ? null : id;
        await this.onChange(value)
        if(this.props.onRowClick){
            await this.props.onRowClick(id);
        }
    };

    onChange = async(value: number | null)=>{
        if(this.props.readonly || this.props.disabled){
            return;
        }
        this.props.onSelect(value)
        await this.getLabels(value);
    }

    getLabels = async(value: number | null)=>{
        this.setState({loadingLabels: true}, async ()=>{
            if(value === null){
                this.setState({valueLabel: "", loadingLabels: false})
                return;
            }
            const entity = ENTITIES[this.props.entity];
            const labels:{id:number, label: string}[] = await server.post("./"+entity.endpoint+"/labels/",[value])
            const valueLabels:{[key:number]: string} = Object.fromEntries(labels.map(x=>[x.id, x.label]))
            this.setState({valueLabel: valueLabels[value], loadingLabels: false})
        })
    }

    onQueryChange = (query:IDBQuery) =>{
        this.setState({query, currPage: 0}, ()=>{
            if(this.props.onQueryChange){
                this.props.onQueryChange(query)
            }
        })
    };


    switchPage = async(p:number)=>{
        this.setState({currPage: p}, ()=>{
            if(this.props.onPageChange){
                this.props.onPageChange(p)
            }
        })
    };

    setSearchTerm = async(s:string)=>{
        this.setState({searchTerm: s, currPage: 0}, ()=>{
            if(this.props.onSearchTermChange){
                this.props.onSearchTermChange(s)
            }
        })
    };

    render() {
        const valueAsArray = this.props.selected === null ? [] : [this.props.selected];
        const picker = <EntityBrowser
            entity={this.props.entity}
            currPage={this.state.currPage}
            searchTerm={this.state.searchTerm}
            query={this.state.query}
            onSearchTermChange={(s)=>{return this.setSearchTerm(s)}}
            onPageChange={(p)=>{return this.switchPage(p)}}
            onQueryChange={(q)=>{return this.onQueryChange(q)}}
            onRowClick={(id)=>{return this.onRowClick(id)}}
            hideExport={true}
            hideQueryEditor={this.props.hideQueryEditor}
            hideFreeSearch={this.props.hideFreeSearch}
            selected={valueAsArray}
        />;
        const selectedValue = <span>
            {
                this.props.selected === null ?
                    <span>{
                        this.props.readonly ?
                            (this.props.noSelectionPlaceholder ? this.props.noSelectionPlaceholder : "") :
                            (this.props.selectPlaceholder ? this.props.selectPlaceholder : "בחירה")
                    }</span> :
                    <span>{
                        this.state.loadingLabels ?
                            <LoadSpinner visibility={true} radius={2}/> :
                            <span>{this.state.valueLabel}</span>
                    }</span>
            }
        </span>
        return (
            <div className={"entity-picker-wrap"}>
            <span className="table-picker-values-wrap">
            {
                this.props.embedInPopup && !this.props.readonly ?
                    <span className={"horizontal-spread"}>
                        <button 
                            type={"button"} 
                            disabled={this.props.disabled} 
                            className={"small-button"}
                            onClick={()=>{this.setState({popupVisibility: true})}}>
                            {selectedValue}
                        </button>
                        {
                            this.props.selected === null ? null :
                                <button type={"button"} disabled={this.props.disabled} onClick={()=>{return this.onChange(null)}}>
                                    <FontAwesomeIcon icon={faTimes}/>
                                </button>
                        }
                    </span>
                    : selectedValue
            }
            </span>
                {this.props.embedInPopup ?
                    <Popup visibility={this.state.popupVisibility}
                           toggleVisibility={()=>{
                               this.setState({popupVisibility: !this.state.popupVisibility})
                           }} width={"80vw"}>
                        {selectedValue}
                        {picker}
                        {this.props.readonly ? null :
                            <button type={"button"} disabled={this.props.disabled} onClick={()=>{
                                this.setState({popupVisibility: !this.state.popupVisibility})
                            }} className={"big-button"}>
                                אישור
                            </button>
                        }
                    </Popup>
                    :
                    picker
                }
            </div>
        )
    }
})