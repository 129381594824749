import "leaflet/dist/leaflet.css";
import React from 'react'
import "./CoordinatePicker.scss"
import Popup from "../Popup/Popup";
import {MapContainer, Marker, TileLayer, Polygon, useMapEvents, Tooltip} from "react-leaflet";
import {Icon, LeafletMouseEvent} from 'leaflet'
import MarkerImg from "../../static/map_marker.png"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLocationDot} from "@fortawesome/free-solid-svg-icons";


interface IProps {
    embedInPopup?: boolean,
    width?: string,
    height?: string,
    value?: {x: number, y: number},
    onChange: (v:{x: number, y: number})=>any,
    readonly?: boolean
}

interface IState {
    popupVisibility: boolean,
    width?: string,
    height?: string,
    markerIcon: Icon,
}

export default class CoordinatePicker extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            popupVisibility: false,
            width: props.width,
            height: props.height,
            markerIcon: new Icon({
                iconUrl: MarkerImg,
                iconSize: [32,32],
                iconAnchor: [16, 32]
            }),
        };
    }

    componentDidMount(): void {}

     clickHandlerComponent = ({onClick}:{onClick:(e:LeafletMouseEvent)=>void}) => {
        const map = useMapEvents({
            click(e:LeafletMouseEvent) {
                onClick(e)
            },
        });
        return null
    };

    openUpPopup = ()=>{
        this.setState({popupVisibility:true});
    };

    closeDownPopup = ()=>{
        this.setState({popupVisibility:false});
    };

    private clickHandler(e: LeafletMouseEvent) {
        if(this.props.readonly){
            return;
        }
        this.props.onChange({x:e.latlng.lat, y:e.latlng.lng})
    }

    render() {
        const coordinates = this.props.value ? this.props.value : {x: 32.0867873, y:34.7895594};
        const map = <MapContainer center={[32.0867873,34.7895594]} zoom={7.5} scrollWheelZoom={true}
                                  style={{
                                      height: this.state.height ? this.state.height: "100%",
                                      width: this.state.width ? this.state.width : "100%",
                                      outline: "none"
                                  }}
        >
            <TileLayer
                attribution='<a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {
                <Marker position={[coordinates.x, coordinates.y]} icon={this.state.markerIcon}>
                    <Tooltip>{JSON.stringify(coordinates)}</Tooltip>
                </Marker>
            }
            <this.clickHandlerComponent
                onClick={(e:LeafletMouseEvent)=>{this.clickHandler(e)}}
            />
        </MapContainer>;
        return <div>
            {
                this.props.embedInPopup ?
                    <span>
                        <button
                            type={"button"}
                            onClick={()=>{this.openUpPopup()}}
                            className={"coordinates-picker-button"}
                        >
                            <span className={"coordinates-picker-icon"}>
                                <FontAwesomeIcon icon={faLocationDot}/>
                            </span>
                            <span dir={"ltr"}>{
                                this.props.value ? `{ ${this.props.value.x.toFixed(6)} , ${this.props.value.y.toFixed(6)} }` : (this.props.readonly ? "-" : "בחירת קואורדינטות")
                            }</span>
                        </button>
                        <Popup dir={"ltr"}
                            visibility={this.state.popupVisibility}
                            toggleVisibility={()=>{this.closeDownPopup()}}
                            width={"80vw"} height={"70vh"}>
                                {map}
                        </Popup>
                    </span>
                    : map
            }
        </div>
    }
}

