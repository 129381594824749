import {FIELD_JUSTIFICATION} from "./fieldControls";

export enum fieldType {
    NONE,
    textType,
    booleanType,
    dateType,
    multipleOptionsType,
    dropdown,
    jsonSchema,
    stringArray,
    contactsQueryType,
    contactCustomFields,
    activityCustomFields,
    entityType,
    entitiesArrayType,
    coordinatesType,
}

export interface IField {
    field: string,
    defaultVal: any,
    type: fieldType,
    options?: any,
    title: string,
    validate?: ((value: any) => {valid: boolean, err?: string})[],
    dir?: FIELD_JUSTIFICATION
}