import "leaflet/dist/leaflet.css";
import React from 'react'
import "./CoordinateSelector.scss"
import Popup from "../Popup/Popup";
import {MapContainer, Marker, TileLayer, Polygon, useMapEvents, Tooltip} from "react-leaflet";
import {Icon, LatLng, LeafletMouseEvent} from 'leaflet'
import MarkerImg from "../../static/map_marker.png"

interface ICityMarker{
    lat: number,
    lng: number,
    label: string
}

export interface IPolygon {
    path: LatLng[]
}

interface IProps {
    embedInPopup?: boolean,
    width?: string,
    height?: string,
    polygons?: IPolygon[],
    onChange: Function
}

interface IState {
    embedInPopup?: boolean,
    popupVisibility: boolean,
    width?: string,
    height?: string,
    markerIcon: Icon,
    polygons: IPolygon[],
    newPolygon: IPolygon | null
    polygonSelectionInProcess: boolean,
    altPressed: boolean,
    markers: ICityMarker[],
    onChange: Function
}

export default class CoordinateSelector extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            embedInPopup: props.embedInPopup,
            popupVisibility: false,
            width: props.width,
            height: props.height,
            markerIcon: new Icon({
                iconUrl: MarkerImg,
                iconSize: [32,32],
                iconAnchor: [16, 32]
            }),
            polygons: props.polygons ? props.polygons : [],
            newPolygon: null,
            polygonSelectionInProcess: false,
            altPressed: false,
            markers: [],
            onChange: props.onChange
        };
    }

    componentDidMount(): void {
        this.getCities();
    }

    static getDerivedStateFromProps(nextProps: IProps, state: IState) {
        return {
            width: nextProps.width,
            height: nextProps.height,
            polygons: nextProps.polygons ? nextProps.polygons : [],
        };
    }

    onKeyDown = (e:KeyboardEvent)=>{
        if (e.repeat) return;
        if(e.key === "Control" || e.key === "Meta"){
            this.setState({polygonSelectionInProcess: true, newPolygon: {path:[]}});
        }
        if(e.key === "Alt"){
            this.setState({altPressed:true})
        }
    };

    onKeyUp = (e:KeyboardEvent)=>{
        if(e.key === "Control" || e.key === "Meta"){
            const polygons = this.state.polygons.slice();
            const p = this.state.newPolygon;
            if(p && p.path && p.path.length){
                p.path.splice(p.path.length - 1, 1)
                if(p.path.length >= 3){
                    polygons.push(p)
                }
            }
            this.setState({polygonSelectionInProcess: false, newPolygon: null}, ()=>{
                this.state.onChange(polygons);
            });
        }
        if(e.key === "Alt"){
            this.setState({altPressed:false})
        }
    };

    getCities = async () => {
        return;/*
        const data = await server.get('cities');
        if(Array.isArray(data)){
            this.setState({
                markers: data.map(c=>{return {
                    lng: c.coordinates.x,
                    lat: c.coordinates.y,
                    label: c.name
                }})
            });
        }*/
    };

    clickHandler = (e:LeafletMouseEvent) => {
        const newPolygon = this.state.newPolygon;
        if(!this.state.polygonSelectionInProcess || !newPolygon){
            return;
        }
        if(newPolygon.path.length === 0){
            newPolygon.path.push(e.latlng);
        }
        newPolygon.path.push(e.latlng);
        this.setState({newPolygon});
    };

    mouseMoveHandler = (e:LeafletMouseEvent) => {
        const newPolygon = this.state.newPolygon;
        if(!this.state.polygonSelectionInProcess || !newPolygon){
            return;
        }
        if(!newPolygon.path.length){
            return;
        }
        newPolygon.path[newPolygon.path.length - 1] = e.latlng;
        this.setState({newPolygon});
    };

    onPolygonClick = (e:LeafletMouseEvent, index:number) => {
        const polygons = this.state.polygons.slice();
        if(this.state.altPressed){
            polygons.splice(index, 1);
            this.state.onChange(polygons);
        }
    };

    clickHandlerComponent = ({onClick, onMouseMove}:{onClick:(e:LeafletMouseEvent)=>void, onMouseMove:(e:LeafletMouseEvent)=>void}) => {
        const map = useMapEvents({
            click(e:LeafletMouseEvent) {
                onClick(e)
            },
            mousemove(e:LeafletMouseEvent){
                onMouseMove(e)
            }
        });
        return null
    };

    openUpPopup = ()=>{
        this.setState({popupVisibility:true});
        window.addEventListener('keydown', this.onKeyDown, false);
        window.addEventListener('keyup', this.onKeyUp, false);
    };

    closeDownPopup = ()=>{
        this.setState({popupVisibility:false});
        window.removeEventListener('keydown', this.onKeyDown, false);
        window.removeEventListener('keyup', this.onKeyUp, false);
    };

    render() {
        const polygons = this.state.polygons.slice();
        const newPolygon = this.state.newPolygon;
        const markers = this.state.markers.slice();
        const map = <MapContainer center={[32.0867873,34.7895594,]} zoom={7.5} scrollWheelZoom={true}
                                  style={{
                                      height: this.state.height ? this.state.height: "100%",
                                      width: this.state.width ? this.state.width : "100%",
                                      outline: "none"
                                  }}
        >
            <TileLayer
                attribution='<a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {
                polygons.map((p, i)=>{
                    return <Polygon
                        key={"polygon_"+i}
                        pathOptions={{color:"#90278e"}}
                        positions={p.path.slice()}
                        eventHandlers={(({onClick}:{onClick:Function})=>{ return{
                            click(e:LeafletMouseEvent) {
                                onClick(e)
                            }
                        }})({onClick:(e:LeafletMouseEvent)=>{this.onPolygonClick(e, i)}})}
                    />
                })
            }
            {
                newPolygon ? <Polygon
                    key={"polygon_new"}
                    pathOptions={{color:"#6027be"}}
                    positions={newPolygon.path.slice()}
                    eventHandlers={(({onClick}:{onClick:Function})=>{ return{
                        click(e:LeafletMouseEvent) {
                            onClick(e)
                        }
                    }})({onClick:()=>{}})}
                /> : null
            }
            {
                markers.map((m, i)=>{
                    return <Marker key={"marker_"+i} position={[m.lng, m.lat]} icon={this.state.markerIcon}>
                        <Tooltip>{m.label}</Tooltip>
                    </Marker>
                })
            }
            <this.clickHandlerComponent
                onClick={(e:LeafletMouseEvent)=>{this.clickHandler(e)}}
                onMouseMove={(e:LeafletMouseEvent)=>{this.mouseMoveHandler(e)}}
            />
        </MapContainer>;
        return <div>
            {
                this.state.embedInPopup ?
                    <span>
                        <button type={"button"}
                                onClick={()=>{this.openUpPopup()}}
                        >
                            <span>סימון אזורים</span> <span>({polygons.length} מסומנים)</span>
                        </button>
                        <Popup dir={"ltr"}
                               visibility={this.state.popupVisibility}
                               toggleVisibility={()=>{this.closeDownPopup()}}
                               width={"80vw"} height={"70vh"}>
                                {map}
                        </Popup>
                    </span>
                    : map
            }
        </div>
    }
}

