import React from 'react'
import {IDBQuery} from "../../UIComponents/QueryBuilder/QueryBuilder";
import server from "../../services/server";
import {IContact} from "../../services/dataManagement/entities/contact";
import LoadSpinner from "../../UIComponents/LoadSpinner/LoadSpinner";
import {ENTITIES} from "../../services/dataManagement/entities";
import {E_ENTITIES} from "../../services/dataManagement/entityDefinitions";

interface IProps {
    searchTerm: string,
    query: IDBQuery,
}
interface IState {
    awaitingPhoneContactsCopy: boolean
}

export default (class PhoneContactsCopier extends React.Component <IProps, IState>{
    constructor(props:IProps) {
        super((props));
        this.state = {
            awaitingPhoneContactsCopy: false,
        };
    }

    render() {
        return this.state.awaitingPhoneContactsCopy ? <LoadSpinner visibility={true} radius={2}/> :
            <button
                type={"button"}
                className={"table-browser-action"}
                onClick={()=>{return this.copyPhoneContactsToClipboard()}}
            >
                העתקה למחולל
            </button>;
    }

    copyPhoneContactsToClipboard = async () => {
        const query = this.props.query;
        const searchTerm = this.props.searchTerm;
        this.setState({awaitingPhoneContactsCopy: true}, async()=>{
            return await server.post("./"+ENTITIES[E_ENTITIES.contacts].endpoint+"/query/",{
                quickSearch: searchTerm,
                query: query,
                disablePagination: true
            }).then((rows:IContact[])=>{
                const contacts = rows.filter(c=>{
                    return c.phone_number && c.phone_number.length > 8
                }).map(c=>{
                    return c.phone_number + "\t" + c.first_name
                }).join("\n");
                navigator.clipboard.writeText(contacts)
                this.setState({awaitingPhoneContactsCopy:false}, ()=>{
                    window.alert("הנתונים של אנשי הקשר הועתקו");
                })
            });
        })
    }
})