import {IEntity} from "../entities";
import {Tables} from "../tables";
import {fieldType, IField} from "../fields";
import {IEntityTableCol} from "../tableColumns";
import {E_ENTITIES} from "../entityDefinitions";
import {ICard} from "../cardControls";
import {IColumnCondition} from "../../../UIComponents/QueryBuilder/QueryBuilder";
import {FIELD_JUSTIFICATION} from "../fieldControls";

export enum EActivityFields {
    id, create_date, update_date, title, activity_type,activity_type_id, tags, tag_ids, custom_fields
}

export const activityFields:{[key in EActivityFields]: IField} = {
    [EActivityFields.id]:{
        field: "id",
        defaultVal: null,
        type: fieldType.textType,
        title: "id"
    },
    [EActivityFields.create_date]:{
        field: "create_date",
        defaultVal: () => { return (new Date()).toISOString() },
        type: fieldType.dateType,
        title: "תאריך יצירה"
    },
    [EActivityFields.update_date]:{
        field: "update_date",
        defaultVal: () => { return (new Date()).toISOString() },
        type: fieldType.dateType,
        title: "תאריך עדכון"
    },
    [EActivityFields.title]:{
        field: "title",
        defaultVal: null,
        type: fieldType.textType,
        title: "כותרת",
        validate: [(v)=>{return (!!v ? {valid: true} : {valid: false, err: "יש להגדיר כותרת"})}],
    },
    [EActivityFields.activity_type_id]:{
        field: "activity_type_id",
        defaultVal: null,
        type: fieldType.entityType,
        options: {entity: E_ENTITIES.activityTypes},
        validate: [(v)=>{return (v!==null ? {valid: true} : {valid: false, err: "יש להגדיר סוג פעילות"})}],
        title: "סוג פעילות",
    },
    [EActivityFields.activity_type]:{
        field: "activity_type",
        defaultVal: null,
        type: fieldType.textType,
        title: "סוג פעילות",
    },
    [EActivityFields.tag_ids]:{
        field: "tag_ids",
        defaultVal: [],
        type: fieldType.entitiesArrayType,
        options: {entity: E_ENTITIES.activityTags},
        title: "תגים",
    },
    [EActivityFields.tags]:{
        field: "tags",
        defaultVal: [],
        type: fieldType.stringArray,
        title: "תגים",
    },
    [EActivityFields.custom_fields]: {
        field: "custom_fields",
        defaultVal: {},
        type: fieldType.activityCustomFields,
        title: "שדות נוספים",
        dir: FIELD_JUSTIFICATION.AUTO_RTL
    },
};

export const ActivityBrowserCols:IEntityTableCol[] = [
    {
        field: activityFields[EActivityFields.create_date],
        readonly: true,
        defaultWidth: 5,
    },
    {
        field: activityFields[EActivityFields.title],
        readonly: true,
        defaultWidth: 10,
    },
    {
        field: activityFields[EActivityFields.activity_type],
        readonly: true,
        defaultWidth: 5,
    },
    {
        field: activityFields[EActivityFields.tags],
        readonly: true,
        defaultWidth: 15,
    },
]

export const ActivityCard:ICard = {
    sections: [
        {
            compact: true,
            hideOnNew: true,
            fields: [
                {
                    field: activityFields[EActivityFields.id],
                    readonly: true
                },
                {
                    field: activityFields[EActivityFields.create_date],
                    readonly: true
                },
                {
                    field: activityFields[EActivityFields.update_date],
                    readonly: true
                }
            ]
        },
        {
            fields: [
                {field: activityFields[EActivityFields.title]},
                {field: activityFields[EActivityFields.activity_type_id]},
                {field: activityFields[EActivityFields.tag_ids]},
            ]
        },
        {
            fields: [
                {field: activityFields[EActivityFields.custom_fields]},
            ]
        }
    ],
    relatedEntities: [
        {
            title: "משתתפים",
            entity: E_ENTITIES.contacts,
            query: (id:number)=> {
                return { "conditions": [({
                        "filter": "f0",
                        "id": "f1",
                        "l": 0,
                        "s": false,
                        "subject": "j_participation_in_activities",
                        "t": 1,
                        "value": {
                            "innerQuery": {
                                "conditions": [{
                                    "filter": "ac_in",
                                    "id": "f2",
                                    "l": 0,
                                    "s": false,
                                    "subject": "c_id",
                                    "t": 1,
                                    "value": [id]
                                }], "table": Tables[Tables.DB_TABLE_ACTIVITY]
                            }, "join": "participation_in_activities"
                        }
                    } as IColumnCondition) ], "table": Tables[Tables.DB_TABLE_CONTACT] }
            }
        },
        {
            title: "מקורות נתונים",
            entity: E_ENTITIES.dataSources,
            query: (id:number)=> {
                return { "conditions": [
                        {
                            "id": "f0",
                            "s": true,
                            "t": 1,
                            "l": 0,
                            "subject": "c_activity_id",
                            "filter": "ac_in",
                            "value": [id]
                        }
                    ], "table": Tables[Tables.DB_TABLE_DATASOURCE] }
            }
        },
    ]
}

export interface IActivity {
    id: number | null,
    create_date: Date | null,
    update_data: Date | null,
    title: string,
    activity_type: number | null,
    activity_type_name?: string,
    tags: number[],
    tagNames?: string[]
}

export const ActivityEntity:IEntity = {
    browsingPage: {
        suffix: "activities",
        title: "פעילויות",
        newItemLabel: "פעילות חדשה",
    },
    cardPage: {
        suffix: "activity",
        title: "פעילות",
    },
    endpoint: "activities",
    fields: Object.values(activityFields),
    table: Tables.DB_TABLE_ACTIVITY,
    tableColumns: ActivityBrowserCols,
    cardLayout: ActivityCard
}