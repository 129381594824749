import React from 'react'
import './JSONEditor.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";
import {ISchemaField, ISchemaFieldType} from "../JSONSchemaEditor/JSONSchemaEditor";
import DatePicker from 'react-date-picker';
import CitySearch from "../CitySearch/CitySearch";

interface IProps {
    data: any,
    schema: ISchemaField,
    onChange: (data: any, field ?: string | number) => void,
    inArray ?: boolean,
    arrayIndex ?: number
}


interface IState {
    data: any,
    schema: ISchemaField,
    onChange: (data: any, field ?: string | number) => void,
    inArray ?: boolean,
    arrayIndex ?: number
}


export default class JSONEditor extends React.Component <IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            data: props.data,
            schema: props.schema,
            onChange: props.onChange,
            inArray: props.inArray
        };
    }

    componentDidMount() {
    }

    static getDerivedStateFromProps(nextProps: IProps, state: IState) {
        return {
            data: nextProps.data,
            schema: nextProps.schema,
            onChange: nextProps.onChange,
            inArray: nextProps.inArray
        };
    }

    onChange = (data:any) => {
        this.state.onChange(data);
    };

    validate = () => {
    };

    onPropertyEdit = (field:string, value: any) => {
        const data = this.state.data ? this.state.data : {};
        data[field] = value;
        this.onChange(data);
    };
    onArrayEntryEdit = (index:number, value: any) => {
        const data = (this.state.data && Array.isArray(this.state.data)) ? this.state.data.slice() : [];
        data[index] = value;
        this.onChange(data);
    };
    addArrayEntry = () => {
        const data = (this.state.data && Array.isArray(this.state.data)) ? this.state.data.slice() : [];
        data.push(null);
        this.onChange(data);
    };
    deleteArrayEntry = (entryIndex:number) => {
        const data = (this.state.data && Array.isArray(this.state.data)) ? this.state.data.slice() : [];
        data.splice(entryIndex, 1);
        this.onChange(data);
    };

    getInputMechanism = (schema:ISchemaField) =>{
        const data = this.state.data;
        switch (schema.fieldType) {
            case ISchemaFieldType[ISchemaFieldType.objectField]:
                return <React.Fragment>
                    <span>תתי-שדות:</span>
                    {
                        schema.properties.slice().map((p, i) => {
                            return <span className={"embedded-object"} key={"property_"+i}>
                                        <JSONEditor key={("subfield_" + i)} data={data ? data[p.fieldKey] : null} schema={p}
                                                    onChange={(data: any) => {
                                                        this.onPropertyEdit(p.fieldKey, data);
                                                    }}
                                        />
                                    </span>
                        })
                    }
                </React.Fragment>
            ;
            case ISchemaFieldType[ISchemaFieldType.stringField]:
                return <input type={"text"} value={data ? data : ""} onChange={(e)=>{this.onChange(e.target.value)}}/>;
            case ISchemaFieldType[ISchemaFieldType.dateField]:
                return <span dir={"ltr"}>
                    <input type={"text"} value={data ? data : ""} onChange={(e)=>{this.onChange(e.target.value)}}/>
                </span>;
            case ISchemaFieldType[ISchemaFieldType.numberField]:
                return <input type={"number"} value={data ? data : ""} onChange={(e)=>{this.onChange(e.target.value)}}/>;
            case ISchemaFieldType[ISchemaFieldType.cityField]:
                return <CitySearch disabled={false} value={data ? data : ""} onChange={(v:number)=>{this.onChange(v)}}/>;
            case ISchemaFieldType[ISchemaFieldType.booleanField]:
                return <div className="boolean-field-wrapper">
                    <input type={"checkbox"} checked={data} onChange={(e)=>{this.onChange(e.target.checked)}}/>
                    <label>
                        {data? 'כן':'לא'}
                    </label>
                </div>
            default: return null;
        }
    };

    render() {
        const schema = this.state.schema;
        const inArray = this.state.inArray;
        const isArray = schema.isArray && !inArray;
        const data = isArray ? (this.state.data && Array.isArray(this.state.data) ? this.state.data : []).slice() : this.state.data;
        return (
            <div className={"json-editor"}>
                {
                    schema.isRoot ? null : <React.Fragment>
                        <span>
                            <span>{isArray ? "רשימה של: " : ""}</span>
                            <span>{schema.fieldTitle}</span>
                            <span>({schema.fieldKey + (inArray ? "["+this.props.arrayIndex+"]" : "")})</span>
                        </span>
                    </React.Fragment>
                }
                {
                    isArray ? <React.Fragment>{
                            (data ? data : []).map((entry: any, i: number) => {
                                return <span className={"array_entry_wrap"}
                                             key={(schema.fieldKey + "_array_entry_" + i +"_at_length_"+(data ? data : []).length)}>
                                    <button
                                        className={"delete-array-entry-button"}
                                        onClick={()=>{this.deleteArrayEntry(i);}}>
                                        <FontAwesomeIcon icon={faTimes}/>
                                    </button>
                                    <JSONEditor data={entry}
                                                schema={schema}
                                                inArray={true}
                                                arrayIndex={i}
                                                onChange={(data: any) => {
                                                    this.onArrayEntryEdit(i, data);
                                                }}
                                    />
                                </span>
                            })
                        }
                            <button type={"button"} className={"add-array-entry-button"} onClick={()=>{this.addArrayEntry()}}>
                                <FontAwesomeIcon icon={faPlus}/>
                                <span>הוספת רשומה חדשה</span>
                            </button>
                    </React.Fragment>
                        : this.getInputMechanism(schema)
                }
            </div>
        )
    }
}

