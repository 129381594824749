import React, {ChangeEvent} from 'react';
import readXLSXFile from 'read-excel-file';
import "./ExcelUploader.scss"
import papa from 'papaparse'


interface IProps {
	onSelect: (data:any[][])=>void,
	labelText: string
}
interface IState {
	onSelect: (data:any[][])=>void,
	labelText: string
}

export default class ExcelUploader extends React.Component <IProps, IState> {
	constructor(props:IProps) {
		super(props);
		this.state = {
			onSelect: props.onSelect,
			labelText: props.labelText ? props.labelText : "Upload a File"
		}
	}
	static getDerivedStateFromProps(nextProps:any){
		return {labelText: nextProps.labelText};
	}

	fileSelectionHandler = (event:ChangeEvent<HTMLInputElement>) =>
	{
		const file = event.target.files ? event.target.files[0] : null;
		if(!file)
			return;
		if(file.name.indexOf(".xls") !== -1) {
			readXLSXFile(file).then(data => {
				this.state.onSelect(data);
			});
		}
		else if(file.name.indexOf(".csv") !== -1){
			const reader = new FileReader();
			reader.onload = ()=>{
				const result = reader.result;
				if(!result || !(typeof result === "string")){
					this.state.onSelect([]);
					return;
				}
				const data = papa.parse(result, {
					header: false,
					dynamicTyping: false,
					skipEmptyLines: true,
				});
				this.state.onSelect(data.data.map((r)=>{
					if(Array.isArray(r)){
						return r;
					}
					return Object.values(r as {[key:string]: any});
				}));
			};
			reader.readAsText(file, "UTF-8");
		}
	};

	render() {
		return (
			<div className="excel-uploader-wrap">
				<div className="fileInputWrap">
					<form method="post">
						<div>
							<label className="big-button" htmlFor="file-upload">{this.state.labelText}</label>
							<input required id={"file-upload"} type="file" accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={this.fileSelectionHandler.bind(this)}/>
						</div>
					</form>
				</div>
			</div>
		)
	}
}
