import React from 'react'
import withRouter from "../services/withRouter";
import TopNavBar from '../UIComponents/TopNavBar/TopNavBar'
import {IDBQuery} from "../UIComponents/QueryBuilder/QueryBuilder";
import {ENTITIES} from "../services/dataManagement/entities";
import EntityBrowser from "../UIComponents/TableComponents/EntityBrowser/EntityBrowser";
import {E_ENTITIES} from "../services/dataManagement/entityDefinitions";
import {Tables} from "../services/dataManagement/tables";

const rison = require('rison-node');

export interface IEntityBrowserPageProps {
	location: any,
	navigate: any,
	params: any
	searchParams: any
	setSearchParams: any
	entity: E_ENTITIES
}
export interface IEntityBrowserPageState {
	query?: IDBQuery,
	currPage: number,
	pageCount: number,
	searchTerm: string,
	loading: boolean,
}

class EntityBrowserPage extends React.Component <IEntityBrowserPageProps, IEntityBrowserPageState> {
	constructor(props: IEntityBrowserPageProps) {
		super(props);
		const queryStr = this.props.searchParams.get("query");
		const currPage = this.props.searchParams.get("p");
		const searchTerm = this.props.searchParams.get("s");
		const query = queryStr ? rison.decode(queryStr) : null;
		this.state = {
			query: query,
			searchTerm: searchTerm ? searchTerm :"",
			currPage: currPage ? currPage : 0,
			loading: false,
			pageCount: 0,
		}
	}

	async componentDidMount() {
		const queryStr = this.props.searchParams.get("query");
		const currPage = this.props.searchParams.get("p");
		const searchTerm = this.props.searchParams.get("s");
		const query = queryStr ? rison.decode(queryStr) : null;
		this.setState({
			query: query,
			searchTerm: searchTerm ? searchTerm :"",
			currPage: currPage ? currPage : 0,
			loading: false,
			pageCount: 0,
		})
	}

	onQueryChange = (query: IDBQuery) => {
		this.setState({query, currPage: 0}, () => {
			this.updateSearchParams();
		})
	};

	switchPage = async (p: number) => {
		this.setState({currPage: p}, () => {
			this.updateSearchParams();
		})
	};

	setSearchTerm = async (s: string) => {
		this.setState({searchTerm: s, currPage: 0}, () => {
			this.updateSearchParams();
		})
	};

	updateSearchParams = () => {
		this.props.setSearchParams({
			s: this.state.searchTerm,
			p: this.state.currPage,
			query: rison.encode(this.state.query)
		});
	}

	goToItem = (id: null | number) => {
		this.props.navigate("/" + ENTITIES[this.props.entity].cardPage.suffix + "/" + ((id === null) ? "": "?id=" + id));
	};

	render() {
		const entity = ENTITIES[this.props.entity];
		return (
				<div className={"contacts-browser-wrap"}>
					<TopNavBar>
						<div className="title-wrap">
							<span className="title-lang">{entity.browsingPage.title}</span>
						</div>
					</TopNavBar>
					<div className="content-wrap">
						<EntityBrowser
							key={"e_" + this.props.entity}
							entity={this.props.entity}
							query={this.state.query}
							currPage={this.state.currPage}
							searchTerm={this.state.searchTerm}
							onQueryChange={(query: IDBQuery) => {
								this.onQueryChange(query)
							}}
							onPageChange={(p: number) => {
								this.switchPage(p)
							}}
							onSearchTermChange={(s: string) => {
								this.setSearchTerm(s)
							}}
							onRowClick={(id: number) => {
								this.goToItem(id)
							}}
						/>
						<hr/>
						<button type={"button"} className={"big-button link-button"} onClick={() => {
							this.goToItem(null);
						}}>
							{entity.browsingPage.newItemLabel}
						</button>
					</div>
				</div>
		)
	}
}
export default (withRouter(EntityBrowserPage));