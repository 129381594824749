import React from 'react'
import server from '../../services/server'
import LoadSpinner from "../../UIComponents/LoadSpinner/LoadSpinner";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import JSONEditor from "../../UIComponents/JSONEditor/JSONEditor";
import {E_ENTITIES} from "../../services/dataManagement/entityDefinitions";
import {ENTITIES} from "../../services/dataManagement/entities";
import {ICustomActivityField} from "../../services/dataManagement/entities/activityCustomFields";

interface IProps {
    data: { [key:string] : any },
    onFieldEdit: (v: { [key:string] : any })=>any
}
interface IState {
    allCustomFields: { [key: string]: ICustomActivityField } | null,
    selectedCustomFields: string[],
}

export default (class ActivityCustomFieldsEditor extends React.Component <IProps, IState>{
        constructor(props:IProps) {
            super(props);
            this.state = {
                allCustomFields: null,
                selectedCustomFields: []
            };
        }

        async componentDidMount() {
            await this.getCustomFields();
        }

        getCustomFields = async () => {
            const data:ICustomActivityField[] = await server.get(ENTITIES[E_ENTITIES.customActivityFields].endpoint + "/");
            const activityCustomFields = this.props.data;
            const selectedCustomFields = data
                .filter(f=>(activityCustomFields[f.field_key]!==undefined))
                .map(f=>f.field_key)
            const customFieldsDict = Object.fromEntries(data.map(f=>[f.field_key, f]))
            this.setState({allCustomFields: customFieldsDict, selectedCustomFields: selectedCustomFields})
        }

        render() {
            const data = this.props.data;
            const allCustomFields = this.state.allCustomFields;
            const selectedCustomFields = this.state.selectedCustomFields.slice();
            const missingFields = allCustomFields ? Object.values(allCustomFields)
                .filter(cf=>!selectedCustomFields.includes(cf.field_key)) : [];
            return (
                <div>
                    {
                        (!data || !allCustomFields) ?
                            <LoadSpinner visibility={true} radius={2}/> :
                            <div>
                                <div className={"card-field-wrap horizontal-spread"}>
                                    {
                                        missingFields.length ?
                                            <React.Fragment>
                                                <span>הוספת שדה</span>
                                                <select onChange={(e) => {
                                                    this.addCustomField(e.target.value)
                                                }}>
                                                    <option value={0}/>
                                                    {
                                                        missingFields
                                                            .map(cf => <option
                                                                value={cf.field_key}
                                                                key={cf.field_key}
                                                            >
                                                                {cf.title}
                                                            </option>)
                                                    }
                                                </select>
                                            </React.Fragment>
                                            : null
                                    }
                                </div>
                                {selectedCustomFields.map(cf => {
                                    const field = allCustomFields[cf];
                                    return <div key={field.field_key} className={"card-field-wrap"}>
                                        <span className={"horizontal-spread"}>
                                            <button type={"button"} onClick={()=>{this.removeCustomFields(cf)}}>
                                                <FontAwesomeIcon icon={faTimes}/>
                                            </button>
                                            <div className={"card-field-title"}>{field.title}</div>
                                        </span>
                                        <JSONEditor data={data[field.field_key]}
                                                    schema={field.field_type}
                                                    onChange={(data)=>{this.setField(field.field_key, data)}}/>
                                    </div>
                                })}
                            </div>
                    }
                </div>
            )
        }

        private addCustomField(f:string) {
            const selectedCustomFields = this.state.selectedCustomFields.slice();
            selectedCustomFields.push(f);
            this.setState({selectedCustomFields})
        }

        private removeCustomFields(f:string) {
            const selectedCustomFields = this.state.selectedCustomFields.slice();
            this.setField(f, undefined)
            this.setState({selectedCustomFields: selectedCustomFields.filter(sf=> (sf !== f))})
        }

        private setField(f: string, v: any) {
            const activityCustomFields = this.props.data;
            if(!activityCustomFields){
                return
            }
            activityCustomFields[f] = v;
            this.props.onFieldEdit(activityCustomFields)
        }
    }
)