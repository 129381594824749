import React from "react";
import "./Voting.scss";
import server from "../../services/server";
import BarChart from "../../UIComponents/BarChart/BarChart";

export default class VotingResults extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      candidates: [],
      selected: [],
      finishedSelecting: false,
      code: "",
      openPopup: false,
      candidateNames: [],
      votingSet: [],
      votesTotal: 0,
      boolVote1: 0,
      boolVote2: 0
    };

    let getCandidates = server.get("candidates");
    let getVotes = server.get("voting");
    Promise.all([getCandidates, getVotes]).then(([candidates, votes]) => {
      this.setState({ votes, votesTotal: votes.length });

      let allVotes = [];

      votes.forEach(vote => (allVotes = allVotes.concat(vote.votes)));

      votes.forEach(vote => {
        if (vote.bool_vote1) this.setState({boolVote1: this.state.boolVote1+1})
        if (vote.bool_vote2) this.setState({boolVote1: this.state.boolVote2+1})
      });

      candidates.forEach(candidate => {
        candidate.votes = allVotes.reduce(
            (acc, curr) => acc + (curr === candidate.id.toString()),
            0
        );
      });

      candidates.sort((a, b) => b.votes - a.votes);
      let votingData = candidates.map(candidate => candidate.votes);

      let candidateNames = candidates.map(
        candidate => candidate.firstname_he + " " + candidate.lastname_he
      );
      this.setState({ candidates, candidateNames });

      // forcing the bar to start at 0
      votingData.push(0);

      const votingSet = [
        {
          label: "קולות",
          backgroundColor: "rgba(144,39,142,0.2)",
          borderColor: "rgba(144,39,142,1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(144,39,142,0.4)",
          hoverBorderColor: "rgba(144,39,142,1)",
          data: votingData
        }
      ];
      this.setState({ votingSet });
    });
  }

  render() {
    return (
      <div className={"page page-wrap-voting-results"}>
        <BarChart
          lables={this.state.candidateNames}
          data={this.state.votingSet}
          votesTotal={this.state.votesTotal}
        />
        <div style={{direction: 'rtl'}}>
          <br/>
          <br/>
          אישור 1: {this.state.boolVote1}/{this.state.votesTotal}
          <br/>
          אישור 2: {this.state.boolVote2}/{this.state.votesTotal}
          <br/>
          <br/>
        </div>
        <div style={{direction: 'rtl'}}>
          {
            this.state.candidateNames.map((candidate, i) => {
              return <div>{candidate},{((this.state.votingSet[0] || {data:[0]})).data[i]}</div>
            })
          }
        </div>
      </div>
    );
  }
}
