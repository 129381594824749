import React from "react";
import {Tables} from "./tables";
import {ISOtoShortDate} from "../utils";
import DatePicker from "react-date-picker";
import MultiSelect from "../../UIComponents/MultiSelect/MultiSelect";
import Dropdown from "../../UIComponents/Dropdown/Dropdown";
import ArrayManager from "../../UIComponents/ArrayManager/ArrayManager";
import JSONSchemaEditor from "../../UIComponents/JSONSchemaEditor/JSONSchemaEditor";
import QueryBuilder from "../../UIComponents/QueryBuilder/QueryBuilder";
import ContactCustomFieldsEditor from "../../pages/contacts/ContactCustomFieldsEditor";
import ActivityCustomFieldsEditor from "../../pages/activities/ActivityCustomFieldsEditor";
import EntityPicker from "../../UIComponents/TableComponents/EntityPicker/EntityPicker";
import MultiEntityPicker from "../../UIComponents/TableComponents/EntityPicker/MultiEntityPicker";
import CoordinatePicker from "../../UIComponents/CoordinatePicker/CoordinatePicker";
import { role } from "../interfaces";

export enum inputType {
    NONE,
    textInput,
    booleanInput,
    dateInput,
    multiSelect,
    dropdown,
    jsonSchemaEditor,
    arrayManager,
    contactsQueryEditor,
    contactCustomFieldsEditor,
    activityCustomFieldsEditor,
    entityPicker,
    entitiesPicker,
    coordinatePicker,
}

export interface IFieldController {
    readonly: boolean,
    disabled: boolean,
    dir: FIELD_JUSTIFICATION,
    value: any,
    options?: any,
    userRole?: role | null,
    onChange: Function,
}

// TODO: add disable/readonly support
export const FIELD_CONTROLS: {[key in inputType]: (f:IFieldController)=>JSX.Element} = {
    [inputType.NONE]:(f:IFieldController)=>
        <span dir={getJustification(f)} title={f.value}>
            {typeof f.value === "object" ? JSON.stringify(f.value) : ((f.value || f.value === 0) ? f.value : "") }
        </span>,
    [inputType.textInput]:(f:IFieldController)=>f.readonly ?
        <span dir={getJustification(f)} title={f.value}>
            {((f.value || f.value === 0) ? f.value : "") }
        </span> :
        <input
            dir={getJustification(f)}
            size={Math.max(15, f.value ? f.value.length : 0) || 15}
            type={"text"}
            value={(f.value || f.value === 0) ? f.value : ""}
            disabled={f.disabled}
            onChange={(e)=> {
                f.onChange(e.target.value)
            }}
        />,
    [inputType.booleanInput]:(f:IFieldController)=><input
        dir={getJustification(f)}
        type={"checkbox"}
        checked={(f.value || f.value === 0) ? f.value : ""}
        disabled={f.disabled}
        readOnly={!(()=>{/*TODO replace with a DIY component that supports read only*/})}
        onChange={(e)=> {
            f.onChange(e.target.value)
        }}
    />,
    [inputType.dateInput]:(f:IFieldController)=> f.readonly ? <span>{ISOtoShortDate(f.value)}</span> : <DatePicker
        disabled={f.disabled}
        value={f.value}
        onChange={(e:any)=>{f.onChange(e.value)}}
        disableCalendar = {true}
        clearIcon = {undefined}
        maxDate = {undefined}
        format="d-M-yy"
    />,
    [inputType.multiSelect]:(f:IFieldController)=>
        <MultiSelect options={f.options}
                     labelIndex='label'
                     valueIndex='id'
                     dir={f.dir}
                     disabled={f.disabled}
                     values={f.value}
                     onChange={(v:any[])=>{f.onChange(v)}}/>,
    [inputType.dropdown]:(f:IFieldController)=>
        <Dropdown options={f.options}
                  labelIndex='label'
                  valueIndex='id'
                  dir={f.dir}
                  disabled={f.disabled}
                  value={f.value}
                  userRole={f.userRole}
                  onChange={(v:any[])=>{f.onChange(v)}}/>,
    [inputType.arrayManager]:(f:IFieldController)=><ArrayManager
        values={f.value}
        onChange={(v:any[])=>{f.onChange(v)}}
        disabled={f.disabled}
        readonly={f.readonly}
    />,
    [inputType.jsonSchemaEditor]:(f:IFieldController)=> <JSONSchemaEditor
        data={f.value}
        onChange={(v:any)=>{f.onChange(v)}}
    />,
    [inputType.contactsQueryEditor]:(f:IFieldController)=><QueryBuilder
        table={Tables.DB_TABLE_CONTACT}
        onChange={(v:any)=>{f.onChange(v)}}
        query={f.value}
        enableJoins={true}
        nested={true}
    />,
    [inputType.contactCustomFieldsEditor]:(f:IFieldController)=><ContactCustomFieldsEditor
        data={f.value}
        onFieldEdit={(v: any) => {f.onChange(v)}}
    />,
    [inputType.activityCustomFieldsEditor]:(f:IFieldController)=><ActivityCustomFieldsEditor
        data={f.value}
        onFieldEdit={(v: any) => {f.onChange(v)}}
    />,
    [inputType.entityPicker]:(f:IFieldController)=><EntityPicker
        entity={f.options.entity}
        onSelect={(value: number | null)=>{
            f.onChange(value);
        }}
        hideQueryEditor={true}
        embedInPopup={true}
        selected={f.value}
        readonly={f.readonly}
    />,
    [inputType.entitiesPicker]:(f:IFieldController)=><MultiEntityPicker
        entity={f.options.entity}
        onSelect={(value: number[])=>{
            f.onChange(value);
        }}
        hideQueryEditor={true}
        embedInPopup={true}
        selected={f.value ? f.value : []}
        readonly={f.readonly}
    />,
    [inputType.coordinatePicker]:(f:IFieldController)=><CoordinatePicker
        embedInPopup={true}
        value={f.value}
        onChange={(v)=>{f.onChange(v)}}
        readonly={f.readonly}
    />
}

export enum FIELD_JUSTIFICATION{
    AUTO, AUTO_RTL, LTR, RTL
}

type IFieldJustification = (v:any)=>boolean

const isRTL = (s:string) => {
    const weakChars       = '\u0000-\u0040\u005B-\u0060\u007B-\u00BF\u00D7\u00F7\u02B9-\u02FF\u2000-\u2BFF\u2010-\u2029\u202C\u202F-\u2BFF',
        rtlChars        = '\u0591-\u07FF\u200F\u202B\u202E\uFB1D-\uFDFD\uFE70-\uFEFC',
        rtlDirCheck     = new RegExp('^['+weakChars+']*['+rtlChars+']');

    return rtlDirCheck.test(s);
};

export const fieldJustification:{[key in FIELD_JUSTIFICATION]: IFieldJustification} = {
    [FIELD_JUSTIFICATION.AUTO]: (v: any)=>{
        try {
            const sv = (typeof v === 'string') ? v : JSON.stringify(v);
            if (v.length) {
                return !isRTL(sv)
            }
            return true;
        }
        catch(e){
            return true;
        }
    },
    [FIELD_JUSTIFICATION.AUTO_RTL]: (v: any)=>{
        try {
            const sv = (typeof v === 'string') ? v : JSON.stringify(v);
            if (v.length) {
                return !isRTL(sv)
            }
            return false;
        }
        catch(e){
            return false;
        }
    },
    [FIELD_JUSTIFICATION.LTR]: ()=>{return true},
    [FIELD_JUSTIFICATION.RTL]: ()=>{return false},
}

export const getJustification = (f:IFieldController) => {
    return f.dir === undefined ? "ltr" : (fieldJustification[f.dir](f.value) ? "ltr": "rtl")
}